import React from 'react';

import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import {
    useGetChannelDetailsMutation,
    useGetChannelThresholdsQuery,
} from '@fiji/common/src/features/deviceManagement/deviceApi';
import {
    setChartConfiguration,
    setGaugeWidgetConfiguration,
    setWidgetConfiguration,
    setWidgetLoader,
} from '@fiji/common/src/features/widgetManagement/widgetConfigurationSlice';

type ReturnProps = {
    isLoading: boolean;
};

export const useGetChannelValues = (widgetType: any): ReturnProps => {
    const dispatch = useAppDispatch();
    const channelPayload = useTypedSelector((state) => state[widgetType]);

    const [getChannelValues, { isLoading, isSuccess, data, isError }] = useGetChannelDetailsMutation();

    const { data: thresholds }: any = useGetChannelThresholdsQuery(
        {
            channelId: channelPayload?.data?.channelId,
            deviceId: channelPayload?.data?.deviceId,
        },
        { skip: !channelPayload?.data?.deviceId || !channelPayload?.data?.channelId }
    );

    React.useEffect(() => {
        if (channelPayload?.primary || channelPayload?.secondary || channelPayload?.data) {
            void deviceDetailsHandler();
        }
    }, [channelPayload]);

    React.useEffect(() => {
        if (thresholds) {
            const customThreshold: any = {};
            thresholds?.data?.threshold?.forEach((item: any) => {
                if (item?.isEnabled) customThreshold[item.name] = item.value;
            });
            dispatch(
                setGaugeWidgetConfiguration({
                    threshold: customThreshold,
                    min: thresholds?.data?.min,
                    max: thresholds?.data?.max,
                })
            );
        }
    }, [thresholds]);

    React.useEffect(() => {
        if (isSuccess || isError) widgetDispatcher();
    }, [isSuccess, isError]);

    React.useEffect(() => {
        if (isError) {
            dispatch(setWidgetLoader(false));
        }
    }, [isError]);

    React.useEffect(() => {
        if (channelPayload?.primary || channelPayload?.secondary || channelPayload?.data) {
            dispatch(setWidgetLoader(true));
        }
    }, [channelPayload]);

    function getPayload(payload: any, key: any): any {
        channelPayload[key]?.forEach((item: any) => {
            const selectedIndex = payload?.findIndex((subItem: any) => subItem.deviceId === item.deviceId);
            const selectedItem = payload?.find((subItem: any) => subItem.deviceId === item.deviceId);
            if (selectedItem) {
                payload?.[selectedIndex]?.channelId.push(item.channelId);
            } else {
                payload?.push({ deviceId: item.deviceId, channelId: [item.channelId] });
            }
        });
    }

    function widgetDispatcher(): void {
        const channelPayloadClone = JSON.parse(JSON.stringify(channelPayload));
        delete channelPayloadClone['multiDevice'];
        switch (widgetType) {
            case 'details':
            case 'properties':
            case 'settings':
            case 'command_bar':
                dispatch(setWidgetConfiguration({ channelPayload: channelPayloadClone, details: data }));
                break;
            case 'gauge':
                dispatch(
                    setGaugeWidgetConfiguration({
                        data: {
                            ...channelPayloadClone?.data,
                            ...(data?.data[0]?.channels[0]?.value && {
                                value: parseFloat(data?.data[0]?.channels[0]?.value ?? '0'),
                            }),
                        },
                        type: channelPayloadClone?.type,
                        viewScale: channelPayloadClone?.viewScale,
                        viewThreshold: channelPayloadClone?.viewThreshold,
                        ...(channelPayloadClone?.data?.multiDevice && {
                            multiDevice: channelPayloadClone?.data?.multiDevice,
                        }),
                    })
                );
                break;
            case 'charts':
                dispatch(setChartConfiguration({ channelPayload: channelPayloadClone, details: data }));
                break;
            default:
                break;
        }
    }

    async function deviceDetailsHandler(): Promise<void> {
        let payload: any = [];
        Object.keys(channelPayload)?.forEach((key: any) => {
            if (key === 'primary' || key === 'secondary') {
                getPayload(payload, key);
            } else if (widgetType === 'gauge') {
                payload = [
                    {
                        deviceId: channelPayload?.data?.deviceId,
                        channelId: [channelPayload?.data?.channelId],
                    },
                ];
            }
        });
        await getChannelValues(payload);
    }

    return {
        isLoading,
    };
};
