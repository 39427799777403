import { useAppDispatch, useTypedSelector } from '@fiji/common';
import { Header } from '@fiji/common/src/types';
import { Add, ChevronRight, Delete, Schedule } from '@mui/icons-material';
import { IconButton, Paper, Radio, Stack, Switch, Typography } from '@mui/material';
import { CustomMenu, CustomModal, CustomTable, CustomTransComponent } from 'components';
import { ModalActions } from 'components/CustomModal';
import moment from 'moment';
import React from 'react';
import { selectedOrg } from '@fiji/common/src/features/orgManagement/orgSlice';
import { DisableScheduleModal } from './DisableScheduleModal';
import { useDeleteScheduleMutation, useGetAllSchedulesQuery } from '@fiji/common/src/features/schedule/scheduleApi';
import { useConfirm } from '@fiji/common/src/hooks';
import { setMessageContent } from '@fiji/common/src/features/common/commonSlice';
import { ScheduleDetails } from './ScheduleDetails';
import { useDebounce, useIsMount, useRBAC } from 'hooks';
import { useFilteredValues } from 'hooks/useFilteredValues';
import { selectCurrentPermission } from '@fiji/common/src/features/profile/profileSlice';
import { useGetFilterActionsQuery } from '@fiji/common/src/features/actions/actionsApi';
import { useTranslation } from 'react-i18next';

const ActionCell = (data: any, detailsModalRef: any): JSX.Element => (
    <IconButton onClick={(): void => detailsModalRef?.current?.handleScheduleDetails(data)}>
        <ChevronRight />
    </IconButton>
);

const EnabledCell = (data: any, disableModalRef: any, disabled: boolean): JSX.Element => (
    <Switch
        disabled={disabled}
        checked={data?.enabled ?? false}
        onChange={(): void => disableModalRef?.current?.handleDisableModal(data)}
    />
);

const NextScheduleCell = (data: any): any => (
    <>
        {data?.nextRun
            ? `${moment(data?.nextRun).format('YYYY/MM/DD')} ${moment(data?.nextRun)?.format('hh:mm A')}`
            : '-'}
    </>
);

const lastRunCell = (data: any): any => (
    <>
        {data?.lastRun
            ? `${moment(data?.lastRun).format('YYYY/MM/DD')} ${moment(data?.lastRun)?.format('hh:mm A')}`
            : '-'}
    </>
);

const getExceptionHolidayContent = (data: any): any => {
    if (data?.exceptionHolidayCountry) {
        return 'Holiday';
    }
    return 'Manual';
};

const ExceptionCell = (data: any): any => <>{data?.excludeHolidays?.length ? getExceptionHolidayContent(data) : '-'}</>;

export const ListView = ({ searchKey }: any): JSX.Element => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const currentOrg = useTypedSelector(selectedOrg);

    const disableModalRef = React.useRef<any>(null);
    const modalId = React.useId();
    const modalRef = React.useRef<any>(null);
    const detailsModalRef = React.useRef<any>(null);
    const [payload, setPayload] = React.useState<any>({ page: 0, size: 10, filters: {} });
    const selectedNode = useTypedSelector((state) => state.common.selectedNode);
    const isMount = useIsMount();
    const permissions = useTypedSelector(selectCurrentPermission);
    const { hasPermission } = useRBAC(permissions);

    const canEditSchedule = hasPermission('edit-schedules');
    const canCreateSchedule = hasPermission('create-schedules');
    const canDeleteSchedule = hasPermission('delete-schedules');

    const frequencyFilterOptions = [
        {
            id: '',
            label: 'All',
        },
        {
            id: 'daily',
            label: 'Daily',
        },
        {
            id: 'weekly',
            label: 'Weekly',
        },
        {
            id: 'monthly',
            label: 'Monthly',
        },
        {
            id: 'yearly',
            label: 'Yearly',
        },
        {
            id: 'once',
            label: 'Once',
        },
    ];
    const [, debouncedValue] = useDebounce(undefined, undefined, searchKey);

    const [deleteSchedule, { isLoading: scheduleDeleting }] = useDeleteScheduleMutation();
    const { data: allActions }: any = useGetFilterActionsQuery(
        { body: {}, params: { all: true } },
        {
            refetchOnMountOrArgChange: true,
            skip: !currentOrg?.id,
        }
    );
    const handleDeleteSchedule = async (): Promise<void> => {
        const { error }: any = await deleteSchedule({ scheduleIds: selectedIds });
        if (!error) {
            dispatch(setMessageContent({ isOpen: true, message: t('SCHEDULING:SCHEDULE_DELETED_SUCCESSFULLY') }));
            onCancel(true);
        }
    };

    const { isVisible, onCancel, onClick, onConfirm } = useConfirm(handleDeleteSchedule);

    const [selectedIds, setSelectedIds] = React.useState<any>();

    const tableRef = React.useRef<any>(null);

    const handleFilterChange = (filters: any, sortData?: any): void => {
        if (JSON.stringify(filters) !== JSON.stringify(payload.filters ?? {})) {
            setPayload((prev: any) => ({
                ...prev,
                page: 0,
                filters: { ...filters, ...(isMount ? payload.filters : {}) },
            }));
        }
        if (sortData && Object.keys(sortData)?.length) {
            setPayload((prev: any) => ({
                ...prev,
                page: 0,
                sort: sortData,
            }));
        }
    };

    React.useEffect(() => {
        if (!isMount) {
            setPayload((prev: any) => ({
                ...prev,
                page: 0,
                searchKey: debouncedValue,
            }));
        }
    }, [debouncedValue]);

    const actionMenu = (): JSX.Element => <CustomMenu menuList={{ menuGroups: getMenuItems() }} />;

    const getDeviceNameCell = (cell: any, key: string): JSX.Element => {
        if (cell[key]?.length > 2) {
            return <>{`${cell[key][0]?.name},${cell[key]?.[1]?.name} and ${cell[key]?.length - 2} others`}</>;
        }
        return cell[key]?.map((item: any) => item?.name ?? '-');
    };
    const handleFrequencyFilterChange = (filter: any): any => {
        setPayload((prev: any) => ({
            ...prev,
            filters: { ...prev.filters, frequency: filter?.id },
        }));
    };
    const handlePagination = (currentPage: number, pageSize: number): void => {
        setPayload((prev: any) => ({
            ...prev,
            page: currentPage,
            size: pageSize,
        }));
    };
    const getFrequencyFilter = (): JSX.Element => (
        <>
            {frequencyFilterOptions?.map((filter: any) => (
                <Stack key={filter?.id} direction={'row'} alignItems={'center'}>
                    <Radio
                        checked={payload?.filters?.frequency === filter?.id}
                        value={payload?.filters?.frequency}
                        onChange={() => handleFrequencyFilterChange(filter)}
                    />
                    <Typography>{filter?.label}</Typography>
                </Stack>
            ))}
        </>
    );
    const getRequiredTableFilters = (filterPayload: any): any => {
        if (selectedNode?.wrapperDeviceType === 'Gateway') {
            return { ...filterPayload, gatewayId: selectedNode?.id };
        }
        if (!selectedNode?.wrapperDeviceType) {
            return { ...filterPayload, groupId: selectedNode?.id ?? currentOrg?.id };
        }
        return { ...filterPayload, deviceId: [selectedNode?.id] };
    };

    // const getExceptionListCell = (cell: any, key: string): JSX.Element => {
    //     if (cell[key]?.length > 2) {
    //         return <>{`${cell[key][0]?.},${cell[key]?.[1]?.name} and ${cell[key]?.length - 2} others`}</>;
    //     }
    //     return cell[key]?.map((item: any) => item?.name?? '-');
    // };

    const DeleteActions: ModalActions[] = [
        {
            key: 'cancel',
            label: <CustomTransComponent translationKey={'COMMON:CANCEL'} />,
            close: true,
            variant: 'outlined',
            handleClick: (): void => {
                onCancel(true);
            },
        },
        {
            key: 'deleteSchedule',
            label: <CustomTransComponent translationKey={'SCHEDULING:DELETE_SCHEDULE'} />,
            variant: 'contained',
            color: 'error',
            disabled: scheduleDeleting,
            className: 'error-btn',
            handleClick: onConfirm,
        },
    ];

    const getMenuItems = (): any => {
        const menuItems: any = [];
        if (canCreateSchedule) {
            menuItems?.push({
                title: 'Add',
                icon: <Add />,
                onClick: (): void => {},
            });
        }
        if (canDeleteSchedule) {
            menuItems?.push({
                title: 'Delete',
                className: !selectedIds?.length ? 'disabled' : '',
                icon: <Delete />,
                onClick: (): void => {
                    onClick();
                },
            });
        }

        return menuItems;
    };
    const tableRows: Header[] = [
        {
            header: '',
            width: '5%',
            isSelectable: true,
        },
        {
            header: t('COMMON:ENABLED'),
            width: '10%',
            accessor: 'enabled',
            cell: (data) => EnabledCell(data, disableModalRef, !canEditSchedule),
        },
        {
            header: t('COMMON:NAME'),
            accessor: 'name',
            width: '15%',
            isFilterable: true,
            isDebounce: true,
            isSortable: true,
        },
        {
            header: t('COMMON:DEVICE'),
            width: '15%',
            accessor: 'deviceName',
            cell: (data) => getDeviceNameCell(data, 'deviceIds'),
            isFilterable: true,
            isDebounce: true,
        },
        {
            header: t('COMMON:ACTION'),
            width: '15%',
            accessor: 'action',
            cell: (data) => getDeviceNameCell(data, 'actions'),
            isFilterable: true,
            filterOptions:
                allActions?.data?.length > 0
                    ? [{ id: 'all', label: 'All' }].concat(
                          allActions?.data?.map((action: any) => ({ id: action?.key, label: action?.displayName }))
                      )
                    : [{ id: 'all', label: 'All' }],
        },
        {
            header: t('SCHEDULING:NEXT_SCHEDULE'),
            width: '15%',
            isSortable: true,
            accessor: 'nextRun',
            cell: (data) => NextScheduleCell(data),
            isFilterable: true,
            isDebounce: true,
        },
        {
            header: t('SCHEDULING:LAST_RUN'),
            width: '15%',
            isSortable: true,
            accessor: 'lastRun',
            cell: (data) => lastRunCell(data),
            isFilterable: true,
            isDebounce: true,
        },
        {
            header: 'Frequency',
            width: '10%',
            accessor: 'frequency',
            isSortable: true,
            isFilterable: true,
            headerOptions: getFrequencyFilter,
        },
        {
            header: t('COMMON:EXCEPTION'),
            width: '10%',
            cell: (data: any) => ExceptionCell(data),
        },
        {
            header: '',
            width: '5%',
            extraOptions: actionMenu,

            accessor: 'actions',
            cell: (data) => ActionCell(data, detailsModalRef),
        },
    ];

    const [tableFilters] = useFilteredValues({ allFilters: tableRows, triggeredFilters: payload?.filters ?? {} });

    const {
        data: currentData,
        isLoading,
        isFetching,
    } = useGetAllSchedulesQuery(
        { ...payload, filters: getRequiredTableFilters(tableFilters) },
        { refetchOnMountOrArgChange: true }
    );

    return (
        <Stack mt={2}>
            <Paper>
                <CustomTable
                    ref={tableRef}
                    data={currentData?.data?.records}
                    headers={tableRows}
                    handleCheckboxSelect={(ids: any) => setSelectedIds(ids)}
                    isPagination
                    handlePageChange={handlePagination}
                    isLoading={isLoading || isFetching}
                    keyToTraverse="id"
                    noDataFoundIcon={<Schedule fontSize="inherit" />}
                    noDataFoundTitle={<CustomTransComponent translationKey={'SCHEDULING:NO_SCHEDULE_ACTIONS'} />}
                    noDataFoundButtonText={<CustomTransComponent translationKey={'SCHEDULING:ADD_SCHEDULE_ACTIONS'} />}
                    total={currentData?.data?.total ?? 10}
                    handleFilterChange={handleFilterChange}
                />
            </Paper>

            {/* Delete Schedule Action popup */}
            <CustomModal
                key={modalId}
                ref={modalRef}
                isOpen={isVisible}
                isLoading={false}
                type="primary"
                actions={DeleteActions}
                header={<CustomTransComponent translationKey={'SCHEDULING:DELETE_SCHEDULES_ACTION'} />}
                noDividers
                actionsDivider
            >
                <Stack>
                    <Typography variant="body1">
                        <CustomTransComponent translationKey={'SCHEDULING:THE_SCHEDULE_WILL_BE_PERMANENTLY_DELETED'} />
                    </Typography>
                </Stack>
            </CustomModal>

            <DisableScheduleModal key="da4f2gc" ref={disableModalRef} />
            <ScheduleDetails ref={detailsModalRef} />
        </Stack>
    );
};
