import React, { useState } from 'react';
import { useTypedSelector } from '@fiji/common';
import { Add, EventNote, Search, ViewList } from '@mui/icons-material';
import { Button, Stack, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { SchedulerLayout } from 'pages/Scheduling/SchedulerLayout';
import { ListView } from 'pages/Scheduling/ListView';
import { useNavigate } from 'react-router-dom';
import { selectedOrg } from '@fiji/common/src/features/orgManagement/orgSlice';
import { useRBAC } from 'hooks';
import { selectCurrentPermission } from '@fiji/common/src/features/profile/profileSlice';
import { CustomTransComponent } from 'components';

export const Schedule = (): JSX.Element => {
    const navigate = useNavigate();
    const currentRealmName = useTypedSelector((state) => state.common.selectedRealm);
    const currentOrg = useTypedSelector(selectedOrg);
    const selectedNode = useTypedSelector((state) => state.common.selectedNode);
    const [searchKey, setSearchKey] = useState<string | undefined>(undefined);
    const [view, setView] = useState('card');

    const handleView = (selection: string): void => {
        setView(selection);
    };

    const permissions = useTypedSelector(selectCurrentPermission);
    const { hasPermission } = useRBAC(permissions);
    const canCreateSchedule = hasPermission('create-schedules');

    return (
        <Stack p={3}>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                <TextField
                    hiddenLabel
                    id="searchKey"
                    value={searchKey ?? ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                        setSearchKey(e.target.value);
                    }}
                    placeholder="Search"
                    size="small"
                    InputProps={{
                        startAdornment: <Search id="search" className="margin-right-6 color-content" />,
                    }}
                />
                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    {canCreateSchedule && (
                        <Button
                            color="primary"
                            variant="contained"
                            startIcon={<Add />}
                            onClick={(): void =>
                                navigate(`/${currentRealmName}/scheduleEvent/${selectedNode?.id ?? currentOrg?.id}`)
                            }
                        >
                            <CustomTransComponent translationKey={'DASHBOARD_MANAGEMENT:ADD_SCHEDULE'} />
                        </Button>
                    )}
                    <ToggleButtonGroup value={view} aria-label="text alignment">
                        <ToggleButton value="card" onClick={() => handleView('card')} aria-label="centered">
                            <EventNote />
                        </ToggleButton>
                        <ToggleButton value="list" onClick={() => handleView('list')} aria-label="centered">
                            <ViewList />
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Stack>
            </Stack>

            {view === 'card' ? <SchedulerLayout searchKey={searchKey} /> : <ListView searchKey={searchKey} />}
        </Stack>
    );
};
