import CryptoJS from 'crypto-js';
/**
 * The function `getUserInitials` takes a name as input and returns the initials of the first and last
 * name capitalized.
 * @param {string} name - A string representing the user's full name.
 * @returns The function `getUserInitials` returns a string representing the initials of a given name.
 */
const getUserInitials = (name: string): string => {
    if (name) {
        const names = name.split(' ');
        let initials = names[0].substring(0, 1).toUpperCase();

        if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
    }
    return '';
};

const getTimeZoneOffset = (timeZone: string): number => {
    const date = new Date();
    const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
    const timeZoneDate = new Date(date.toLocaleString('en-US', { timeZone }));
    const offset = utcDate.getTime() - timeZoneDate.getTime();
    return offset;
};

function downloadUsingBlob(blob: any, filename: any): any {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', filename);
    a.style.display = 'none';
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}

const getRealmNameFromSuffix = (currentRealmName: string): string =>
    (window?.APP_CONFIG?.SUFFIX ? currentRealmName?.replace(`${window?.APP_CONFIG?.SUFFIX}_`, '') : currentRealmName) ??
    '';

const getFullName = (firstName: string, lastName: string): string => {
    if (!firstName && !lastName) {
        return `-`;
    }
    return `${firstName ?? ''} ${lastName ?? ''}`;
};

const createPayload = (data: any): any[] => {
    const payload: any[] = [];

    // Group channels by deviceId
    const deviceMap: { [key: string]: any[] } = {};

    data?.forEach((item: any) => {
        const { deviceId, channelId } = item;

        // If the deviceId already exists, push the channelId to its array
        if (deviceMap[deviceId]) {
            deviceMap[deviceId]?.push(channelId);
        } else {
            // If the deviceId is new, create a new array for its channels
            deviceMap[deviceId] = [channelId];
        }
    });

    // Construct the payload array from the grouped device data
    for (const deviceId in deviceMap) {
        payload?.push({
            deviceId: deviceId,
            channelId: deviceMap[deviceId],
        });
    }

    return payload;
};

/**
 * The function takes a string as input and returns the same string with the first letter converted to
 * uppercase.
 * @param {string} str - The parameter `str` is a string that represents the input text.
 */
const toFirstLetterUpperCase = (str: string): string => str.charAt(0).toUpperCase() + str.slice(1);

/**
 * The function checks if a given value or array of values contains only whitespace characters.
 * @param {any} data - The `data` parameter is of type `any`, which means it can accept any data type.
 * @returns The function `handleWhiteSpaces` returns a boolean value.
 */
const handleWhiteSpaces = (data: any): any => {
    if (data === '' || data === null) {
        return true;
    } else if (typeof data === 'string') {
        return Boolean(data?.trim());
    } else if (Array.isArray(data)) {
        return data.every((str: string) => (str === '' || str === null ? true : Boolean(str?.trim())));
    }
    return false;
};

/**
 * The function `createNumArray` creates an array of numbers from 1 to the specified length.
 * @param {number} length - The `length` parameter is a number that represents the desired length of
 * the array to be created.
 * @returns The function `createNumArray` returns an array of numbers.
 */
const createNumArray = (length: number): number[] => {
    const data: number[] = [];
    for (let i = 0; i < length; i++) {
        data.push(i + 1);
    }
    return data;
};

/**
 * The function `getEventColor` takes a key as input and returns a corresponding color code based on
 * the key.
 * @param {string} key - The `key` parameter is a string that represents the type of event.
 * @returns The function `getEventColor` returns a string value. The specific string value that is
 * returned depends on the value of the `key` parameter passed to the function. If the `key` parameter
 * is equal to 'Alarm', the function returns the string '#CA3C3D'. If the `key` parameter is equal to
 * 'Offline', the function returns the string '#69B1C
 */
const getEventColor = (key: string): string => {
    /* The `switch (key)` statement is used to evaluate the value of the `key` variable and execute
    different code blocks based on different cases. Each `case` represents a possible value of the
    `key` variable. If the value of `key` matches a `case`, the corresponding code block is
    executed. If none of the `case` values match the value of `key`, the code block under the
    `default` case is executed (if present). */
    switch (key) {
        case 'alarms': {
            return '#CA3C3D';
        }
        case 'offlineDevices': {
            return '#69B1C3';
        }
        case 'warnings': {
            return '#F0AA1F';
        }
        default: {
            return '#007BC1';
        }
    }
};

/**
 * The function `getEventsLabel` takes a key as input and returns a corresponding label for events.
 * @param {string} key - The `key` parameter is a string that represents the type of event. It can have
 * one of the following values: 'warnings', 'alarms', 'information', or 'offlineDevices'.
 * @returns The function `getEventsLabel` returns a string value. The specific string value that is
 * returned depends on the input `key` parameter. If the `key` parameter matches one of the cases in
 * the switch statement, the corresponding label string is returned. If the `key` parameter does not
 * match any of the cases, an empty string is returned.
 */
const getEventsLabel = (key: string): string => {
    switch (key) {
        case 'warnings': {
            return 'Warnings';
        }
        case 'alarms': {
            return 'Alarms';
        }
        case 'information': {
            return 'Info';
        }
        case 'offlineDevices': {
            return 'Offline';
        }
        default: {
            return '';
        }
    }
};

/**
 * The function `getEventIconStyle` returns an object with color and font size properties based on the
 * provided key and size parameters.
 * @param {string} key - The `key` parameter is a string that represents the type or category of an
 * event.
 * @param {number} size - The size parameter is a number that represents the font size in pixels.
 */
const getEventIconStyle = (key: string, size: number): object => ({ color: getEventColor(key), fontSize: `${size}px` });

/**
 * The function `getTimelineDetailsStyle` returns an object with CSS styles for a timeline event based
 * on a given key.
 * @param {string} key - The `key` parameter is a string that represents the key of an event.
 */
const getTimelineDetailsStyle = (key: string): object => ({
    color: '#fff',
    backgroundColor: getEventColor(key),
    padding: '6px',
    borderRadius: '50%',
    height: '40px',
    width: '40px',
});

/**
 * The function `getPriorityEvent` returns the highest priority event based on the properties of the
 * `source` object.
 * @param {any} source - The `source` parameter is an object that represents some source of data. It is
 * expected to have a `stats` property, which is also an object. The `stats` object may have properties
 * such as `alarms`, `offlineDevices`, or `warnings`. The function `getPriorityEvent
 * @returns The function `getPriorityEvent` returns a string value.
 */
const getPriorityEvent = (source: any): string => {
    if (source?.stats?.alarms) {
        return 'alarms';
    } else if (source?.stats?.offlineDevices || source?.status === 'Offline') {
        return 'offlineDevices';
    } else if (source?.stats?.warnings) {
        return 'warnings';
    }
    return '';
};

/**
 * The function `downloadFileFromLink` creates a hidden link element, sets the link and filename
 * attributes, appends it to the document body, triggers a click event to download the file, and then
 * removes the link element from the document body.
 * @param {string} link - The `link` parameter is a string that represents the URL of the file you want
 * to download. It should be a valid URL that points to the file you want to download.
 * @param {string} filename - The `filename` parameter is a string that represents the desired name of
 * the downloaded file.
 */
const downloadFileFromLink = (link: string, filename: string): void => {
    const element = document.createElement('a');
    element.setAttribute('href', link || window.URL.createObjectURL(new Blob(['Test,Text'], { type: 'text/txt' })));
    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
};

const downloadFile = (url: string, filename: string): void => {
    const link = document.createElement('a'); // Create an anchor element
    link.setAttribute('href', url); // Set the href to the file URL
    link.setAttribute('download', filename); // Set the download attribute with the desired filename
    link.style.display = 'none';
    document.body.appendChild(link); // Append the link to the document body
    link.click(); // Programmatically click the link to trigger the download
    document.body.removeChild(link); // Remove the link from the document
};

const encodeBase64 = (dataToEncode: string): string => Buffer.from(dataToEncode).toString('base64');
const decodeBase64 = (encodedData: string): string => Buffer.from(encodedData, 'base64').toString();

/**
 * The above function copies the current organization's code to the clipboard and displays a toast
 * message indicating successful copying.
 * @returns The `copyCodeHandler` function returns a Promise that resolves to `void`.
 */
const copyCodeHandler = (copyContent: string): Promise<void> => navigator.clipboard.writeText(copyContent ?? '');

const findAnglesOfTriangle = (height: number, width: number): any => {
    const perpendicular = height;
    const base = width / 2;
    const hypotenuse = Math.sqrt(perpendicular * perpendicular + base * base);
    let alpha = Math.acos(
        (base * base + hypotenuse * hypotenuse - perpendicular * perpendicular) / (2 * base * hypotenuse)
    );
    let beta = Math.acos(
        (perpendicular * perpendicular + hypotenuse * hypotenuse - base * base) / (2 * perpendicular * hypotenuse)
    );
    let gamma = Math.acos(
        (perpendicular * perpendicular + base * base - hypotenuse * hypotenuse) / (2 * perpendicular * base)
    );
    alpha = (alpha * 180) / Math.PI;
    beta = (beta * 180) / Math.PI;
    gamma = (gamma * 180) / Math.PI;
    return {
        alpha,
        beta,
        gamma,
        perpendicular,
        base,
        hypotenuse,
    };
};
const formatTime = (timeData: any): string => {
    let timeStr: string = '';
    if (timeData?.hours > 0) {
        timeStr += `${timeData?.hours}h`;
    }
    if (timeData?.minutes > 0 || timeData?.hours > 0) {
        timeStr += timeData?.minutes < 10 ? `0${timeData?.minutes}m` : `${timeData?.minutes}m`;
    }
    timeStr += timeData?.seconds < 10 ? `0${timeData?.seconds}s` : `${timeData?.seconds}s`;
    return timeStr;
};

const camelCaseToCapitalizedSpaceSeparated = (str: string): string => {
    // Add space between camel case words
    const stringWithSpaces = str.replace(/([a-z])([A-Z])/g, '$1 $2');
    // Capitalize the first letter
    return stringWithSpaces.charAt(0).toUpperCase() + stringWithSpaces.slice(1);
};

function stringToCamelCase(str: string): string {
    const ans = str.toLowerCase();
    // Splitting the string by space and then mapping over each word
    // Capitalizing the first letter of each word and joining them back
    return ans
        .split(' ')
        .map((word, index) => (index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)))
        .join('');
}

const generateAesKey = (secret: string): any => {
    const keyHash = CryptoJS.SHA384(secret);
    const key = CryptoJS.enc.Hex.parse(keyHash.toString(CryptoJS.enc.Hex).slice(0, 64));
    return key;
};

const decryptAES = (encryptedText: string, saltJSON: object): any => {
    const decryptedText = CryptoJS.AES.decrypt(encryptedText, generateAesKey(encodeBase64(JSON.stringify(saltJSON))), {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
    }).toString(CryptoJS.enc.Utf8);
    let decryptMessage;
    try {
        decryptMessage = JSON.parse(decryptedText);
    } catch (e) {
        decryptMessage = decryptedText;
    }
    return decryptMessage;
};
const getListItemBackgroundColor = (data: any, isDetails?: boolean): string => {
    if (
        data?.status === 'Active' &&
        (data?.severity === 'Alarm' || isDetails) &&
        data?.severity !== 'Offline' &&
        !data?.isAcknowledged
    ) {
        return '#CA3C3D';
    } else if (data?.status === 'Active' && data?.severity === 'Offline') {
        return '#69B1C3';
    } else if (data?.status === 'Active' && data?.isAcknowledged) {
        return '#E0F1FD';
    } else if (data?.severity === 'Alarm' && data?.isAcknowledged && data?.isCleared) {
        return '#D5D8DA';
    }
    return 'transparent';
};

const getListItemTextColor = (data: any): string => {
    if (data?.status === 'Cleared' || data?.severity === 'Cleared') {
        return '#424E54';
    } else if (data?.severity === 'Alarm' && data?.isAcknowledged) {
        return '#0088F2';
    } else if (data?.status === 'Active' && data?.severity === 'Offline') {
        return '#DBEEF2';
    }
    return '#FFFFFF';
};

const getTimelineBorder = (data: any): string => {
    if ((data?.severity === 'Alarm' && data?.isAcknowledged && data?.isCleared) || !data?.isCleared) {
        return 'none';
    }
    return '1px solid #424E54';
};

const getUTCTimestamp = (timestamp: number): number => timestamp + new Date().getTimezoneOffset() * 60000;

const areAllDataArraysEmpty = (jsonData: any[]): boolean => {
    if (!Array.isArray(jsonData)) {
        return true;
    }

    // Return false if there's at least one non-empty data array
    const hasNonEmptyData = jsonData.some((series) => {
        if (!series || !('data' in series)) {
            return true;
        }
        return Array.isArray(series.data) && series.data.length > 0;
    });

    // If we didn't find any non-empty data array, all must be empty
    return !hasNonEmptyData;
};

export {
    getUserInitials,
    getTimeZoneOffset,
    handleWhiteSpaces,
    createNumArray,
    getRealmNameFromSuffix,
    toFirstLetterUpperCase,
    downloadFileFromLink,
    getEventColor,
    getEventsLabel,
    getEventIconStyle,
    getTimelineDetailsStyle,
    getPriorityEvent,
    downloadUsingBlob,
    getFullName,
    encodeBase64,
    decodeBase64,
    copyCodeHandler,
    findAnglesOfTriangle,
    formatTime,
    camelCaseToCapitalizedSpaceSeparated,
    stringToCamelCase,
    decryptAES,
    getListItemBackgroundColor,
    getListItemTextColor,
    getTimelineBorder,
    createPayload,
    getUTCTimestamp,
    areAllDataArraysEmpty,
    downloadFile,
};
