const DEFAULT_WIDGET_LAYOUT: any = {
    gauge: {
        w: 15,
        h: 9,
    },
    common: {
        w: 20,
        h: 7,
    },
};

export { DEFAULT_WIDGET_LAYOUT };
