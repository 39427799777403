import React from 'react';

import { useParams } from 'react-router-dom';
import { ExpandMore } from '@mui/icons-material';
import { useTransformData } from 'hooks/useTransformData';
import { AccordionSummary, Stack, Typography } from '@mui/material';
import { useGetTimelineDetailsByIdQuery } from '@fiji/common/src/features/Timeline/timelineApi';
import { CustomTransComponent } from 'components';

export const EventsSummary = ({ category }: { category: any }): JSX.Element => {
    const { timelineId } = useParams();

    const { convertDateTime } = useTransformData();

    const { data } = useGetTimelineDetailsByIdQuery(timelineId, { skip: !timelineId });
    return (
        <AccordionSummary
            expandIcon={<ExpandMore color="primary" />}
            aria-controls="panel1bh-content"
            id="expand"
            className="border-bottom-1"
        >
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} className="w-100">
                <Typography variant="subtitle2" color={'primary'} fontSize={'14px'} fontWeight={'600'}>
                    {category ?? '-'}
                </Typography>
                <Stack direction={'row'}>
                    <Stack direction={'row'} spacing={2} mr={'106px'}>
                        <Typography variant="body2" fontSize={'12px'} fontWeight={'300'}>
                            <CustomTransComponent translationKey={'TIMELINE:AT_TIME_OF_EVENT'} />
                        </Typography>
                        <Typography fontWeight={'600'} fontSize={'12px'} className="text-secondary">
                            {convertDateTime({
                                timestamp: parseInt(data?.data?.alarmOccurred),
                                customFormat: 'HH:mm:ss',
                            }) || '-'}{' '}
                            {convertDateTime({
                                timestamp: parseInt(data?.data?.alarmOccurred),
                            }) || '-'}
                        </Typography>
                    </Stack>
                    <Stack direction={'row'} spacing={2} mr={'106px'}>
                        <Typography variant="body2" fontSize={'12px'} fontWeight={'300'}>
                            <CustomTransComponent translationKey={'TIMELINE:AT_LAST_UPDATE'} />
                        </Typography>
                        <Typography fontWeight={'600'} fontSize={'12px'} className="text-secondary">
                            {convertDateTime({
                                timestamp: parseInt(data?.data?.alarmUpdated ?? data?.data?.alarmOccurred),
                                customFormat: 'HH:mm:ss',
                            }) || '-'}{' '}
                            {convertDateTime({
                                timestamp: parseInt(data?.data?.alarmUpdated ?? data?.data?.alarmOccurred),
                            }) || '-'}
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>
        </AccordionSummary>
    );
};
