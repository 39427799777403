import React, { Suspense, lazy } from 'react';

import { CustomTabs, CustomTransComponent } from 'components';
import { useLocation } from 'react-router-dom';
import { PreviewDeviceModal } from './PreviewDeviceModal';
import { PreviewWidgetCardContent } from './PreviewWidgetCardContent';
import { HierarchySelectionHeader } from './HierarchySelectionHeader';
import { Card, CardHeader, Grid, Paper, Stack, Typography } from '@mui/material';
import { ConfigurationTabSkeleton, DisplayPreferencesSkeleton } from '../common/Skeletons';
import LinkOff from '@mui/icons-material/LinkOff';
import { useAppDispatch } from '@fiji/common';
import { setWidgetLoader } from '@fiji/common/src/features/widgetManagement/widgetConfigurationSlice';
import { CustomEmptyState } from 'components/CustomEmptyState';

const tabsStyle = { display: 'flex', flexDirection: 'row', marginBottom: '1px', width: '50%' };

const NoDataAvailable = ({ tabType }: { tabType: string }): JSX.Element => {
    const dispatch = useAppDispatch();

    React.useEffect(() => {
        dispatch(setWidgetLoader(false));
    }, []);

    return (
        <CustomEmptyState
            className="padding-y-96"
            icon={<LinkOff className="height-100 width-100" />}
            title={`${tabType} not available`}
        />
    );
};

const getDefaultComponent = (tabType: any): JSX.Element => <NoDataAvailable tabType={tabType} />;
const renderCustomTab = (tabType: string, state: any): any => {
    const DynamicComponent = lazy(() =>
        import(`../Widgets/${state?.typeId}/${tabType}`)
            .then((module: any) => ({
                default: module.default,
            }))
            .catch(() => ({ default: () => getDefaultComponent(tabType) }))
    );

    return (
        <Suspense
            fallback={tabType === 'DisplayPreferences' ? <DisplayPreferencesSkeleton /> : <ConfigurationTabSkeleton />}
        >
            <DynamicComponent />
        </Suspense>
    );
};
export const WidgetConfigurationBody: any = React.memo((): JSX.Element => {
    const { state } = useLocation();

    const tabsData = [
        {
            id: 'configuration',
            sx: tabsStyle,
            renderTitle: <CustomTransComponent translationKey={'WIDGETS:WIDGET_CONFIGURATION.CONFIGURATION'} />,
            renderContent: renderCustomTab('Configurations', state),
        },
        {
            id: 'display Preferences',
            sx: tabsStyle,
            renderTitle: <CustomTransComponent translationKey={'WIDGETS:WIDGET_CONFIGURATION.DISPLAY_PREFRENCES'} />,
            renderContent: renderCustomTab('DisplayPreferences', state),
        },
    ];

    return (
        <>
            <Stack p={3}>
                <Grid container width={'auto'} spacing={2}>
                    <Grid item xs={8}>
                        <Paper>
                            <CustomTabs tabsData={tabsData} hideAppbar hideToolbar noHashRouting />
                        </Paper>
                    </Grid>
                    <Grid item xs={4}>
                        <HierarchySelectionHeader />
                        <Card>
                            <CardHeader
                                className="border-bottom-1 padding-0"
                                title={
                                    <Typography
                                        variant="body2"
                                        textTransform={'uppercase'}
                                        fontWeight={'600'}
                                        letterSpacing={2}
                                        className="border-bottom-1 bg-header"
                                        p={3}
                                    >
                                        <CustomTransComponent translationKey={'COMMON:PREVIEW'} />
                                    </Typography>
                                }
                            />

                            <PreviewWidgetCardContent />
                        </Card>
                    </Grid>
                </Grid>
            </Stack>
            <PreviewDeviceModal />
        </>
    );
});
