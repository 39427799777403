import React from 'react';
import {
    BatteryChargingFullSharp,
    Check,
    ElectricMeter,
    EnergySavingsLeaf,
    ErrorOutline,
    ProductionQuantityLimits,
    Savings,
    TrendingDown,
    TrendingFlat,
    TrendingUp,
} from '@mui/icons-material';
import { Card, CardContent, Divider, Stack, Typography } from '@mui/material';
import { ChannelValue } from '@brightlayer-ui/react-components';
import WidgetNotConfigured from 'pages/EditWidget/WIdgetNotConfigured';
import { WidgetHeader } from 'pages/WidgetManagement/common';
import { HeaderAction } from '../aggregated_trends/HeaderAction';

const currencySymbols = {
    DOLLAR_SYMBOL: '$',
    EURO_SYMBOL: '€',
    SWISS_FRANC_SYMBOL: 'CHF',
};

const Component = (props: any): JSX.Element => {
    const renderIcon = (trend: string): JSX.Element => {
        switch (trend) {
            case 'UP_RED':
                return <TrendingUp sx={{ color: '#F47721' }} fontSize="large" />;
            case 'DOWN_GREEN':
                return <TrendingDown sx={{ color: '#39B620' }} fontSize="large" />;
            default:
                return <TrendingFlat color="primary" fontSize="large" />;
        }
    };

    const renderChecks = (trend: string): JSX.Element => {
        if (trend === 'UP_RED') {
            return <ErrorOutline color="error" />;
        }
        return <Check color="success" />;
    };

    const getCurrencySymbol = (currency: string): string => {
        switch (currency?.toLowerCase()) {
            case 'dollar':
                return currencySymbols.DOLLAR_SYMBOL;
            case 'euro':
                return currencySymbols.EURO_SYMBOL;
            case 'swiss franc':
                return currencySymbols.SWISS_FRANC_SYMBOL;
            default:
                return currency;
        }
    };

    const getTrend = (trend: string): string => {
        switch (trend) {
            case 'UP_RED':
                return 'At risk';
            default:
                return 'On Track';
        }
    };

    const getAbsentDataTitle = (): string | undefined => {
        if (props?.widgetData?.multiDevice && props?.widgetData?.assets?.length === 0) {
            return 'Widget Not Configured';
        }

        const hasConsumptionData =
            props?.widgetData?.secondary?.consumption &&
            Object.keys(props?.widgetData?.secondary?.consumption).length > 0;

        if ((!hasConsumptionData && !props?.widgetData?.multiDevice) || props?.widgetData?.assets?.length) {
            return 'No Data Found';
        }

        return undefined;
    };

    return (
        <Card sx={{ height: '100%' }}>
            {props?.widgetData?.viewTitleBar && (
                <>
                    <WidgetHeader
                        mode={props?.mode}
                        widgetData={props?.widgetData}
                        widgetFilter={props?.widgetFilter}
                        {...((props?.widgetData?.viewTimePeriod ||
                            props?.widgetData?.widgetTypeId === 'aggregated_trends') && {
                            headerAction: (
                                <HeaderAction
                                    mode={props?.mode}
                                    widgetData={props?.widgetData}
                                    widgetFilter={props?.widgetFilter}
                                    downloadCSVHandler={props?.downloadCSVHandler}
                                />
                            ),
                        })}
                    />
                    {props?.mode !== 'edit' && <Divider />}
                </>
            )}
            <CardContent className="padding-0">
                {props?.widgetData?.secondary?.consumption &&
                Object?.keys(props?.widgetData?.secondary?.consumption)?.length ? (
                    <Stack
                        pt={2}
                        px={'30px'}
                        direction={'column'}
                        justifyContent={'space-around'}
                        className="flex-100 height-160"
                    >
                        <Stack direction={'row'} spacing={'6%'}>
                            <Stack>
                                <Stack flexDirection={'row'} alignItems={'center'}>
                                    <EnergySavingsLeaf color="primary" />
                                    <Typography variant="body1" pl={1}>
                                        Consumption
                                    </Typography>
                                </Stack>

                                <Stack>
                                    <Stack flexDirection={'row'} alignItems={'center'}>
                                        {renderIcon(props?.widgetData?.secondary?.consumption?.trend)}
                                        <ChannelValue
                                            value={parseFloat(props?.widgetData?.secondary?.consumption?.value).toFixed(
                                                2
                                            )}
                                            units={props?.widgetData?.secondary?.consumption?.unit}
                                            pl={1}
                                            fontSize={34}
                                        />
                                    </Stack>
                                    <Stack flexDirection={'row'} alignItems={'center'}>
                                        {renderChecks(props?.widgetData?.secondary?.consumption?.trend)}
                                        <Typography variant="body1" pl={1}>
                                            {getTrend(props?.widgetData?.secondary?.consumption?.trend)}
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Stack>
                            <Stack>
                                <Stack flexDirection={'row'} alignItems={'center'}>
                                    <Savings color="primary" />
                                    <Typography variant="body1" pl={1}>
                                        Cost
                                    </Typography>
                                </Stack>
                                <Stack>
                                    <Stack flexDirection={'row'} alignItems={'center'}>
                                        {renderIcon(props?.widgetData?.secondary?.cost?.trend)}
                                        <ChannelValue
                                            value={parseFloat(props?.widgetData?.secondary?.cost?.value).toFixed(2)}
                                            units={getCurrencySymbol(props?.widgetData?.secondary?.cost?.unit)}
                                            pl={1}
                                            fontSize={34}
                                        />
                                    </Stack>
                                    <Stack flexDirection={'row'} alignItems={'center'}>
                                        {renderChecks(props?.widgetData?.secondary?.cost?.trend)}
                                        <Typography variant="body1" pl={1}>
                                            {getTrend(props?.widgetData?.secondary?.cost?.trend)}
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Stack>

                        <Stack direction={'row'} spacing={'6%'} sx={{ paddingTop: '5%' }}>
                            <Stack>
                                <Stack flexDirection={'row'} alignItems={'center'}>
                                    <BatteryChargingFullSharp color="primary" />
                                    <Typography variant="body1" pl={1}>
                                        EV Charger
                                    </Typography>
                                </Stack>

                                <Stack>
                                    <Stack flexDirection={'row'} alignItems={'center'}>
                                        {renderIcon(props?.widgetData?.secondary?.evChargerConsumption?.trend)}
                                        <ChannelValue
                                            value={parseFloat(
                                                props?.widgetData?.secondary?.evChargerConsumption?.value
                                            ).toFixed(2)}
                                            units={props?.widgetData?.secondary?.evChargerConsumption?.unit}
                                            pl={1}
                                            fontSize={34}
                                        />
                                    </Stack>
                                    <Stack flexDirection={'row'} alignItems={'center'}>
                                        {renderChecks(props?.widgetData?.secondary?.evChargerConsumption?.trend)}
                                        <Typography variant="body1" pl={1}>
                                            {getTrend(props?.widgetData?.secondary?.evChargerConsumption?.trend)}
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Stack>
                            <Stack>
                                <Stack flexDirection={'row'} alignItems={'center'}>
                                    <ElectricMeter color="primary" />
                                    <Typography variant="body1" pl={1}>
                                        Self Sufficiency
                                    </Typography>
                                </Stack>
                                <Stack>
                                    <Stack flexDirection={'row'} alignItems={'center'}>
                                        {renderIcon(props?.widgetData?.secondary?.selfSufficiency?.trend)}
                                        <ChannelValue
                                            value={parseFloat(
                                                props?.widgetData?.secondary?.selfSufficiency?.value
                                            ).toFixed(2)}
                                            units={getCurrencySymbol(
                                                props?.widgetData?.secondary?.selfSufficiency?.unit
                                            )}
                                            pl={1}
                                            fontSize={34}
                                        />
                                    </Stack>
                                    <Stack flexDirection={'row'} alignItems={'center'}>
                                        {renderChecks(props?.widgetData?.secondary?.selfSufficiency?.trend)}
                                        <Typography variant="body1" pl={1}>
                                            {getTrend(props?.widgetData?.secondary?.selfSufficiency?.trend)}
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Stack>

                        <Stack sx={{ paddingTop: '5%' }}>
                            <Stack flexDirection={'row'} alignItems={'center'}>
                                <ProductionQuantityLimits color="primary" />
                                <Typography variant="body1" pl={1}>
                                    Production
                                </Typography>
                            </Stack>
                            <Stack>
                                <Stack flexDirection={'row'} alignItems={'center'}>
                                    {renderIcon(props?.widgetData?.secondary?.production?.trend)}
                                    <ChannelValue
                                        value={parseFloat(props?.widgetData?.secondary?.production?.value).toFixed(2)}
                                        units={getCurrencySymbol(props?.widgetData?.secondary?.production?.unit)}
                                        pl={1}
                                        fontSize={34}
                                    />
                                </Stack>
                                <Stack flexDirection={'row'} alignItems={'center'}>
                                    {renderChecks(props?.widgetData?.secondary?.production?.trend)}
                                    <Typography variant="body1" pl={1}>
                                        {getTrend(props?.widgetData?.secondary?.production?.trend)}
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack>
                ) : (
                    <WidgetNotConfigured title={getAbsentDataTitle()} />
                )}
            </CardContent>
        </Card>
    );
};

export default Component;
