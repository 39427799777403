/* eslint-disable @typescript-eslint/no-this-alias */
import React, { useRef, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Box, Button, Divider, FormControl, MenuItem, Paper, Radio, Select, Stack } from '@mui/material';

import { WidgetFooter } from 'pages/WidgetManagement/common/WidgetFooter';
import { WidgetHeader } from 'pages/WidgetManagement/common/WidgetHeader';
import { MultiColumnList } from 'components/MultiColumnList';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import Download from '@mui/icons-material/Download';
import { useIsMount } from 'hooks';
import { StyledMenu } from 'pages/Chart/StyledMenu';
import { TREND_INITIAL_CONFIG } from 'pages/WidgetManagement/common';
import { getRandomColor, getTimeInFromTo } from '@fiji/common/src/utils/helpers';
import { useTransformData } from 'hooks/useTransformData';
import { seriesColors, useTypedSelector } from '@fiji/common';
import { useDispatch } from 'react-redux';
import { setTrendsLegendData } from '@fiji/common/src/features/widgetManagement/trendsWidgetSlice';
import { useGetChannelUnitsQuery } from '@fiji/common/src/features/deviceManagement/deviceApi';
import { CustomTransComponent } from 'components';

export type TelemetryChartProps = {
    type?: 'area' | 'line';
    widgetTypeId?: string;
    route?: string;
    state?: string;
    maxCount: number;
    secondary: any[];
    widgetName: string;
    [key: string]: any;
};

const Component = React.forwardRef(
    (
        {
            widgetData,
            mode,
            payloadChangeHandler,
            downloadHandler,
            handlerLegendsPayload,
        }: {
            widgetData: TelemetryChartProps;
            mode?: string;

            payloadChangeHandler: any;
            downloadHandler: any;
            handlerLegendsPayload: any;
        },
        ref
    ): any => {
        const dispatch = useDispatch();
        const trendsLegendData: any = useTypedSelector(
            (state) => state.trends.trendsLegendData[widgetData?.widgetName]
        );
        const { pointConversion, timezoneOffset } = useTransformData();
        const componentRef: any = useRef(null);
        const [chartHeight, setChartHeight] = useState<any>(null);

        const isMount = useIsMount();
        const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

        const chartRef: any = useRef(null);

        const [channelIds, setChannelIds] = useState<any>([]);
        const [activeDuration, setActiveDuration] = useState<number>(widgetData?.duration ?? 24);
        const [selectedUnits, setSelectedUnits] = React.useState<any>(widgetData?.selectedUnit ?? '');
        const [isRefreshed, setIsRefreshed] = React.useState(false);
        const open = Boolean(anchorEl);

        const [options, setOptions] = useState<any>(TREND_INITIAL_CONFIG);

        const { data: unitList } = useGetChannelUnitsQuery(getPayload(), {
            skip: !getPayload()?.length || mode !== 'view',
        });

        const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = (): void => {
            setAnchorEl(null);
        };

        React.useEffect(() => {
            if (!isMount) {
                setIsRefreshed(false);
                if (!isRefreshed)
                    payloadChangeHandler?.({
                        payload: channelIds,
                        activeDuration: getTimeInFromTo(activeDuration, 'h'),
                    });
            }
        }, [activeDuration]);

        React.useEffect(() => {
            if (!isMount && channelIds) {
                setIsRefreshed(false);
                if (!isRefreshed)
                    handlerLegendsPayload?.({
                        payload: channelIds,
                        activeDuration: getTimeInFromTo(activeDuration, 'h'),
                    });
            }
        }, [channelIds]);

        function getPayload(): any {
            const payload: any = [];
            widgetData?.secondary?.forEach((item: any) => {
                const selectedIndex = payload?.findIndex((subItem: any) => subItem.deviceId === item.deviceId);
                const selectedItem = payload?.find((subItem: any) => subItem.deviceId === item.deviceId);
                if (selectedItem) {
                    payload?.[selectedIndex]?.channelId.push(item.channelId);
                } else {
                    payload?.push({ deviceId: item.deviceId, channelId: [item.channelId] });
                }
            });
            return payload;
        }

        const getSeriesData = (data: any): any[] =>
            data?.map((trend: any, index: number) => ({
                id: `${trend?.deviceId}-${trend?.channelId}`,
                name: trend?.displayName,
                data: trend?.data?.map((point: any): any => [point?.x, point?.y]),
                color:
                    trendsLegendData?.legendData?.find?.(
                        (item: any) => `${trend?.deviceId}_${trend?.channelId}` === item?.uId
                    )?.color ?? seriesColors[index],
                type: widgetData?.type ?? 'line',
                tooltip: {
                    valueSuffix: trend?.unit ? ` ${trend?.unit}` : '',
                },
            }));

        const getUnit = (axis: any): string => {
            let unit = '';
            const current: any = chartRef?.current;
            current?.chart?.series.forEach((series: any): void => {
                if (series?.dataMax === axis?.axis?.dataMax) {
                    unit = series?.tooltipOptions?.valueSuffix;
                }
            });
            return unit;
        };

        const getYAxis = (): any => ({
            title: {
                text: '',
            },
            labels: {
                formatter: function (): any {
                    const yAxis: any = this;
                    return `${pointConversion(yAxis?.value)}${getUnit(this)}`;
                },
            },
        });

        const addPoints = (message: any): void => {
            const current: any = chartRef?.current;
            const seriesIndex = current?.chart?.series.findIndex(
                (s: any) => s?.userOptions?.id === `${message?.deviceId}-${message?.channelId}`
            );

            if (seriesIndex !== -1) {
                current?.chart?.series?.[seriesIndex]?.addPoint([message.timestamp, +message.value], true, false);
                if (
                    message?.unit &&
                    current?.chart?.series?.[seriesIndex]?.tooltipOptions?.valueSuffix !== ` ${message?.unit}`
                ) {
                    current?.chart?.series?.[seriesIndex]?.update({
                        tooltip: { valueSuffix: ` ${message?.unit}` },
                    });
                    current?.chart?.yAxis[seriesIndex].update({
                        title: {
                            text: '',
                        },
                        labels: {
                            formatter: function (): any {
                                const yAxis: any = this;
                                const unit = ` ${message?.unit}`;
                                return `${pointConversion(yAxis?.value)}${unit}`;
                            },
                        },
                    });
                }
                current?.chart?.hideLoading();
            }
        };

        React.useImperativeHandle(
            ref,
            () => ({
                addPoints: (message: any): void => addPoints(message),
            }),
            [widgetData]
        );

        React.useEffect(() => {
            if (
                !trendsLegendData ||
                (widgetData?.channelData &&
                    trendsLegendData?.legendData?.length !==
                        widgetData?.channelData?.slice(0, widgetData?.maxCount ?? widgetData?.secondary?.length)
                            ?.length)
            ) {
                setIsRefreshed(true);
                const newColors = JSON.parse(JSON.stringify(seriesColors));

                const legendDataN = widgetData?.channelData
                    ?.slice(0, widgetData?.maxCount ?? widgetData?.secondary?.length)
                    ?.map((item: any, index: number) => {
                        let color: string = getRandomColor();
                        if (index < widgetData?.secondary?.length) {
                            color = newColors[0];
                            newColors.shift();
                        }
                        return {
                            channelId: item?.id ?? item?.channelId,
                            id: item?.channelId,
                            uId: `${item?.deviceId}_${item?.channelId}`,
                            deviceId: item?.deviceId,
                            color: color,
                            visible: Boolean(index < widgetData?.secondary?.length),
                            name: item?.displayName,
                        };
                    });
                dispatch(
                    setTrendsLegendData({
                        id: widgetData?.widgetName,
                        value: {
                            legendData: legendDataN,
                            colors: newColors,
                        },
                    })
                );
                setChannelIds(
                    widgetData?.channelData?.filter(
                        (channels: any) =>
                            legendDataN?.find((item: any) => item?.channelId === (channels?.id ?? channels?.channelId))
                                ?.visible
                    )
                );
            }
        }, [widgetData?.channelData]);

        const getFilter = (): JSX.Element => (
            <Box display={'flex'} alignItems={'center'} position={'absolute'} right={0}>
                <FormControl sx={{ minWidth: 80 }}>
                    <Select
                        disabled={mode !== 'view'}
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        label="Age"
                        variant="standard"
                        placeholder="15 Days"
                        disableUnderline
                        defaultValue={widgetData?.duration ?? 24}
                        onChange={(e: any): void => setActiveDuration(e?.target?.value)}
                    >
                        <MenuItem value={24}>
                            <CustomTransComponent translationKey={'MULTI_TRENDS:TWENTY_FOUR_HOURS'} />
                        </MenuItem>
                        <MenuItem value={360}>
                            <CustomTransComponent translationKey={'MULTI_TRENDS:FIFTEEN_DAYS'} />
                        </MenuItem>
                        <MenuItem value={744}>
                            <CustomTransComponent translationKey={'MULTI_TRENDS:THIRTY_ONE_DAYS'} />
                        </MenuItem>
                    </Select>
                </FormControl>
                <Button
                    disabled={mode !== 'view'}
                    startIcon={
                        <Download
                            sx={{
                                color: mode !== 'view' ? '#b2b2b8' : '#727e84',
                            }}
                        />
                    }
                    onClick={(): void => downloadHandler?.()}
                    className="download-icon"
                />
            </Box>
        );

        React.useEffect(() => {
            const chart = chartRef?.current?.chart;
            let data: any = JSON.parse(JSON.stringify(widgetData?.secondary ?? []));

            if (selectedUnits) {
                data = data?.filter((trend: any) => trend?.unit === selectedUnits);
            }
            const newOption = JSON.parse(JSON.stringify(options));
            if (
                chart &&
                widgetData?.secondary?.length &&
                data?.some((item: any) => Boolean(item?.data?.length)) &&
                trendsLegendData?.legendData?.length
            ) {
                newOption['yAxis'] = getYAxis();
                newOption['series'] = getSeriesData(
                    data?.filter(
                        (trend: any) =>
                            trendsLegendData?.legendData?.find?.(
                                (item: any) => `${trend?.deviceId}_${trend?.channelId}` === item?.uId
                            )?.visible
                    )
                );

                newOption['chart'] = {
                    type: widgetData?.type ?? 'line',
                    ...(chartHeight && { height: chartHeight }),
                    zoomType: 'x',
                    zooming: {
                        mouseWheel: {
                            enabled: true,
                            sensitivity: 1.1,
                            type: 'x',
                        },
                    },
                };
                setOptions(newOption);
                chart?.hideLoading();
            } else if (chart) {
                newOption['series'] = [];
                setOptions(newOption);
            }
        }, [widgetData, selectedUnits, trendsLegendData, chartHeight]);

        React.useEffect(() => {
            if (!isNaN(timezoneOffset)) {
                Highcharts.setOptions({
                    time: {
                        timezoneOffset: timezoneOffset,
                    },
                    lang: {
                        thousandsSep: '',
                    },
                });
            }
        }, [timezoneOffset]);

        const changeHandler = (e: any, id: number): void => {
            const newLegendData = JSON.parse(JSON.stringify(trendsLegendData?.legendData ?? []));
            const newColors = JSON.parse(JSON.stringify(trendsLegendData?.colors ?? []));
            const index = newLegendData?.findIndex((legend: any): any => legend.channelId === id);

            if (index > -1) {
                newLegendData[index].visible = e.target.checked;
                if (e.target.checked) {
                    newLegendData[index].color = newColors[0];
                    newColors?.shift();
                } else {
                    newColors.unshift(newLegendData[index].color);
                }
            }

            setChannelIds(
                widgetData?.channelData?.filter(
                    (channels: any) =>
                        newLegendData?.find((item: any) => item?.channelId === (channels?.id ?? channels?.channelId))
                            ?.visible
                )
            );
            dispatch(
                setTrendsLegendData({
                    id: widgetData?.widgetName,
                    value: {
                        legendData: newLegendData,
                        colors: newColors,
                    },
                })
            );
            const current: any = chartRef?.current;
            if (current?.chart?.series?.length > 0) current?.chart.series.forEach((series: any) => series.remove());
        };

        const handleResizeComponent = (): void => {
            const hightWidth = componentRef.current?.getElementsByClassName('d-flex')?.[0]?.getBoundingClientRect();
            setChartHeight(
                Math.floor((componentRef.current.getBoundingClientRect()?.height ?? 0) - (hightWidth?.height ?? 0) - 75)
            );
        };

        React.useEffect(() => {
            handleResizeComponent();
            window?.addEventListener('resize', handleResizeComponent);
            componentRef?.current?.addEventListener('resize', handleResizeComponent);
        }, []);

        return (
            <Paper sx={{ height: '100%' }}>
                <WidgetHeader mode={mode} widgetData={widgetData} headerAction={getFilter()} />
                {mode !== 'edit' && <Divider />}
                <Stack
                    sx={{
                        height: `calc(100% - 97px)`,
                        overflow: 'auto',
                        justifyContent: 'space-between',
                    }}
                    ref={componentRef}
                >
                    <Stack
                        display="flex"
                        flexDirection="row"
                        justifyContent="flex-end"
                        alignItems={'flex-end'}
                        p={2}
                        pr={5}
                    >
                        <Box mr={2}>
                            <Button
                                disabled={mode !== 'view'}
                                id="demo-customized-button"
                                aria-controls={open ? 'demo-customized-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                sx={{
                                    backgroundColor: 'transparent',
                                    color: '#424E54',
                                }}
                                onClick={handleClick}
                                endIcon={!open ? <ArrowDropDown /> : <ArrowDropUp />}
                            >
                                <CustomTransComponent translationKey={'COMMON:UNITS'} />
                            </Button>
                            <StyledMenu
                                id="demo-customized-menu"
                                MenuListProps={{
                                    'aria-labelledby': 'demo-customized-button',
                                }}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                            >
                                {unitList?.data?.map((item: any): any => (
                                    <MenuItem key={item} value={item} className="w-100">
                                        <Radio
                                            className="margin-right-16"
                                            checked={selectedUnits === item}
                                            onChange={(): void => setSelectedUnits(item)}
                                        />
                                        {item}
                                    </MenuItem>
                                ))}
                            </StyledMenu>
                        </Box>
                    </Stack>
                    <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} />
                    <MultiColumnList
                        isDisabled={mode !== 'view'}
                        isLoading={false}
                        legendData={trendsLegendData?.legendData ?? []}
                        changeHandler={changeHandler}
                        maxItemsPerColumn={5}
                    />
                </Stack>
                <WidgetFooter
                    mode={mode ?? ''}
                    ids={[]}
                    widgetData={{ ...widgetData, viewAllButton: true }}
                    disabled={!widgetData?.secondary?.length}
                    actionBtnName="View Full Trendviewer"
                />
            </Paper>
        );
    }
);
export default Component;
