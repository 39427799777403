import React from 'react';

import {
    List,
    Radio,
    Stack,
    ListItem,
    TextField,
    Typography,
    RadioGroup,
    FormHelperText,
    FormControlLabel,
} from '@mui/material';
import { useConfirm } from '@fiji/common/src/hooks';
import { CustomModal, CustomTransComponent } from '../../../components';
import { ModalActions } from '../../../components/CustomModal';
import { useHandleAddWidgets } from './hooks';
import { resetWidget, setPayload } from '@fiji/common/src/features/widgetManagement/widgetSlice';
import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import { FormSkeleton } from './Skeletons';
import { handleWhiteSpaces } from 'utils/helpers';
import { selectCurrentPermission } from '@fiji/common/src/features/profile/profileSlice';
import { useRBAC } from 'hooks';
import { useTranslation } from 'react-i18next';

export const NewWidgetModal = React.forwardRef((_props, ref): JSX.Element => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const payload = useTypedSelector((state) => state.widget?.payload);
    const { widgetCreationLoader: isLoading, handleSubmit } = useHandleAddWidgets();

    const handleModalAction = (action: boolean): void => {
        if (action) {
            onClick();
            return;
        }
        onCancel();
    };

    React.useImperativeHandle(
        ref,
        () => ({
            handleModalAction: (action: any): void => handleModalAction(action),
        }),
        []
    );
    const { isVisible, onCancel, onClick, onConfirm } = useConfirm((): Promise<void> => handleSubmit(onCancel));

    const permissions = useTypedSelector(selectCurrentPermission);

    const { hasPermission } = useRBAC(permissions);
    const canCreateSystemWidgets = hasPermission('create-system-widgets');
    const canCreateUserWidgets = hasPermission('create-user-widgets');

    const newWidgetModalActions: ModalActions[] = [
        {
            key: '#creationcancel',
            label: <CustomTransComponent translationKey={'COMMON:CANCEL'} />,
            close: true,
            variant: 'outlined',
            handleClick: (): void => {
                onCancel();
                dispatch(resetWidget());
            },
        },
        {
            key: '#creationsave',
            label: isLoading ? (
                <CustomTransComponent translationKey={'COMMON:SAVING'} />
            ) : (
                <CustomTransComponent translationKey={'COMMON:SAVE_LABEL'} />
            ),
            variant: 'contained',
            disabled: !handleWhiteSpaces(payload?.name) || !payload?.name || isLoading,
            isLoading: isLoading,
            handleClick: (): void => {
                onConfirm();
            },
        },
    ];

    return (
        <CustomModal
            key="#new!@1313d"
            type="primary"
            actionsDivider
            header={<CustomTransComponent translationKey={'WIDGETS:ADD_WIDGET'} />}
            actions={newWidgetModalActions}
            isOpen={isVisible}
            onClose={onCancel}
        >
            {isLoading && <FormSkeleton />}
            {!isLoading && (
                <>
                    <Typography variant="subtitle1" fontWeight={'600'} my={2}>
                        <CustomTransComponent translationKey={'WIDGETS:CLONE_MODAL_HEADER'} />
                    </Typography>
                    <TextField
                        fullWidth
                        id="name"
                        name="name"
                        label={t('COMMON:NAME')}
                        value={payload?.name ?? ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                            dispatch(setPayload({ key: e.target.name, value: e.target.value }));
                        }}
                        variant="filled"
                        inputProps={{ maxLength: 32 }}
                        className="margin-top-18"
                        required
                    />
                    <Stack direction={'row'} justifyContent={'space-between'} px={2}>
                        <FormHelperText>
                            <CustomTransComponent translationKey={'COMMON:REQUIRED_LABEL'} />
                        </FormHelperText>
                        <FormHelperText>{`${payload?.name?.length ?? 0}/32`}</FormHelperText>
                    </Stack>
                    <TextField
                        id="description"
                        fullWidth
                        variant="filled"
                        name="description"
                        value={payload?.description ?? ''}
                        inputProps={{ maxLength: 128 }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                            dispatch(setPayload({ key: e.target.name, value: e.target.value }));
                        }}
                        label={<CustomTransComponent translationKey={'ALARM_DETAILS:DESCRIPTION_TITLE'} />}
                        className="margin-top-18"
                    />
                    <FormHelperText className="text-align-end padding-x-16">{`${
                        payload?.description?.length ?? 0
                    }/128`}</FormHelperText>
                    {canCreateSystemWidgets && canCreateUserWidgets && (
                        <>
                            <Typography variant="subtitle1" fontSize={'16px'} fontWeight={'600'} mt={'18px'}>
                                <CustomTransComponent
                                    translationKey={'NEW_WIDGET_MODAL:IS_THIS_A_SYSTEM_OR_USER_WIDGET'}
                                />
                            </Typography>
                            <List>
                                <RadioGroup
                                    id="creationmodalradio"
                                    defaultValue={true}
                                    onChange={(e: any): any => {
                                        dispatch(
                                            setPayload({
                                                key: 'isSystemWidget',
                                                value: Boolean(e.target.value !== 'false'),
                                            })
                                        );
                                    }}
                                >
                                    <ListItem>
                                        <FormControlLabel
                                            value={true}
                                            control={<Radio />}
                                            id="system"
                                            label={
                                                <Typography variant="body1">
                                                    <span className="f-16 fw-600 black-500">
                                                        <CustomTransComponent
                                                            translationKey={'NEW_WIDGET_MODAL:SYSTEM_WIDGET'}
                                                        />{' '}
                                                    </span>{' '}
                                                    <CustomTransComponent
                                                        translationKey={
                                                            'DASHBOARDS:CLONE_MODAL_DASHBOARD.SYSTEM_DASHBOARD_DESCRIPTION'
                                                        }
                                                    />
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <FormControlLabel
                                            value={false}
                                            id="user"
                                            control={<Radio />}
                                            label={
                                                <Typography variant="body1">
                                                    <span className="f-16 fw-600 black-500">
                                                        <CustomTransComponent
                                                            translationKey={'NEW_WIDGET_MODAL:USER_WIDGET'}
                                                        />{' '}
                                                    </span>{' '}
                                                    <CustomTransComponent
                                                        translationKey={
                                                            'NEW_WIDGET_MODAL:PRIVATE_DISPLAYED_ONLY_TO_YOU'
                                                        }
                                                    />
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                </RadioGroup>
                            </List>
                        </>
                    )}
                </>
            )}
        </CustomModal>
    );
});
