import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { handleChannelRemove, handleReallocateChannels } from './helpers';

const initialState: any = {
    data: undefined,
    type: 'solidGauge',
    viewScale: false,
    viewThreshold: false,
    selectedNodes: [],
};

const scoreWidgetSlice = createSlice({
    name: 'score',
    initialState: initialState,
    reducers: {
        resetScoreConfiguration: () => {
            return initialState;
        },
        setPredefinedScore: (state: RootState, action: PayloadAction<any>) => {
            Object.keys(action.payload)?.forEach((key: string) => {
                state[key] = action.payload[key];
            });
        },
        setScore: (state: RootState, action: PayloadAction<any>) => {
            state.data = action.payload;
        },
        removeScoreParameter: (state: RootState) => {
            state.data = {};
        },
        setScoreFilters: (state: RootState, action: PayloadAction<any>) => {
            state['filters'] = action.payload;
        },
        setScoreSelectedNodes: (state: RootState, action: PayloadAction<any>) => {
            state[action.payload.key] = action.payload.value;
        },
        removeScoreNode: (state: RootState, action: PayloadAction<any>) => {
            handleChannelRemove(state, action, 'id');
        },
        reallocateScoreFilters: (state: any, action: PayloadAction<any>) => {
            handleReallocateChannels(state, action);
        },
        setScorePreferences: (state: RootState, action: PayloadAction<any>) => {
            state[action.payload.key] = action.payload.value;
        },
    },

    extraReducers: (builder) => {
        builder.addCase('widgetConfiguration/setWidgetDevice', (state, action: any) => {
            if (action.payload.reset) state.data = { ...(state.data ?? {}), deviceId: action.payload.data.id };
        });
    },
});

export const {
    removeScoreParameter,
    resetScoreConfiguration,
    setPredefinedScore,
    setScorePreferences,
    setScore,
    removeScoreNode,
    setScoreSelectedNodes,
    reallocateScoreFilters,
    setScoreFilters,
} = scoreWidgetSlice.actions;

export default scoreWidgetSlice;
