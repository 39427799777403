import React from 'react';

import CustomIcon from '../../components/CustomIcon';
import { useParams } from 'react-router-dom';
import { SettingsRightComponent } from './';
import { Container, List, Paper } from '@mui/material';
import { InfoListItem } from '@brightlayer-ui/react-components';
import { CustomAppBar, CustomTransComponent } from '../../components';
import { useGetSettingsQuery, useUpdateSettingsMutation } from '@fiji/common/src/features/deviceManagement/deviceApi';
import { ListSkeleton } from 'pages/WidgetManagement/common/Skeletons';
import { setMessageContent } from '@fiji/common/src/features/common/commonSlice';
import { CustomEmptyState } from 'components/CustomEmptyState';
import { useDispatch } from 'react-redux';
import { useRBAC } from 'hooks';
import { useTypedSelector } from '@fiji/common';
import { selectCurrentPermission } from '@fiji/common/src/features/profile/profileSlice';
import { useTranslation } from 'react-i18next';

export const Settings = (): JSX.Element => {
    const { deviceId } = useParams();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const {
        data: settingsData,
        isLoading,
        isFetching,
    } = useGetSettingsQuery({ deviceId }, { skip: !deviceId, refetchOnMountOrArgChange: true });

    const [updateDeviceSettings, { isLoading: updateLoader }] = useUpdateSettingsMutation();

    const permissions = useTypedSelector(selectCurrentPermission);
    const { hasPermission } = useRBAC(permissions);
    const canUpdateDevice = hasPermission('edit-devices');

    const [payload, setPayload] = React.useState<any>({});

    React.useEffect(() => {
        if (settingsData?.data?.length) {
            setDefaultSettings();
        }
    }, [settingsData]);

    const isSaveDisable = (): boolean => {
        let bool = true;
        settingsData?.data?.map((item: any) => {
            if (item?.currentValue !== payload[item?.channelId]) {
                bool = false;
            }
        });
        return bool;
    };

    const setDefaultSettings = (): void => {
        const requiredObject: any = {};
        settingsData?.data?.forEach((item: any) => {
            requiredObject[item?.channelId] = item?.currentValue;
        });
        setPayload(requiredObject);
    };

    const updateSettings = async (): Promise<void> => {
        const payloadClone: any = [];
        for (const key in payload) {
            const selectedIndex = payloadClone?.findIndex((item: any) => item?.channelId === key);
            const selectedSettingsData = settingsData?.data?.find((item: any) => item?.channelId === key);
            if (selectedSettingsData?.currentValue !== payload[key]) {
                if (selectedIndex === -1) {
                    payloadClone.push({ channelId: key, tagValue: payload[key] });
                } else {
                    payloadClone[selectedIndex].value = payload[key];
                }
            }
        }
        const { error }: any = await updateDeviceSettings({ body: payloadClone, deviceId });
        if (!error) {
            dispatch(setMessageContent({ isOpen: true, message: t('SETTINGS:SETTINGS_UPDATED_SUCCESSFULLY') }));
        }
    };

    const getSwitchContent = (item: any): any => {
        if (item?.dataType === 'boolean') {
            return 'toggle';
        }
        return 'text';
    };

    const getSettingsContent = (): any => {
        if (settingsData?.data?.length) {
            return (
                <List disablePadding>
                    {settingsData?.data?.map(
                        (item: any): JSX.Element => (
                            <InfoListItem
                                key={item?.id}
                                className="border-bottom-1"
                                icon={<CustomIcon family={item?.icon?.web?.family} iconName={item?.icon?.web?.name} />}
                                title={item?.displayName}
                                rightComponent={
                                    <SettingsRightComponent
                                        item={item}
                                        type={item?.values?.length ? 'select' : getSwitchContent(item)}
                                        payload={payload}
                                        setPayload={setPayload}
                                    />
                                }
                            />
                        )
                    )}
                </List>
            );
        }
        return (
            <CustomEmptyState icon title={<CustomTransComponent translationKey={'COMMON:NO_SETTINGS_AVAILABLE'} />} />
        );
    };

    return (
        <>
            {canUpdateDevice && (
                <CustomAppBar
                    isSaveOrDiscardButtonDisabled={isSaveDisable() || updateLoader}
                    handleDiscard={setDefaultSettings}
                    handleSubmit={updateSettings}
                />
            )}

            <Container maxWidth="lg" className="padding-y-24">
                <Paper>
                    {isLoading || isFetching ? (
                        <ListSkeleton total={3} customSize={25} customSx={{ height: '4.5rem' }} />
                    ) : (
                        getSettingsContent()
                    )}
                </Paper>
            </Container>
        </>
    );
};
