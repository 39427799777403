import React from 'react';

import { Header } from '@fiji/common/src/types';
import CustomIcon from '../../../components/CustomIcon';
import { CustomTable, GroupPath, Hierarchy } from '../../../components';
import { useTypedSelector } from '@fiji/common/src/app/store';
import { useNavigate, useParams } from 'react-router-dom';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Search from '@mui/icons-material/Search';
import TrendingUp from '@mui/icons-material/TrendingUp';
import { useGetPredictionFiltersQuery } from '@fiji/common/src/features/prediction/predictionApi';
import { Avatar, Chip, IconButton, Paper, Stack, TextField, Typography } from '@mui/material';
import { selectedOrg } from '@fiji/common/src/features/orgManagement/orgSlice';
import { useGetAllGroupsMutation } from '@fiji/common/src/features/group/groupApi';
import { GroupHierarchySkeleton } from 'pages/GroupHierarchy/GroupHierarchySkeleton';
import { useFilteredValues } from 'hooks/useFilteredValues';
import { useDebounce, useIsMount } from '@fiji/common/src/hooks';
import { toFirstLetterUpperCase } from 'utils/helpers';
import { ListItemTag } from '@brightlayer-ui/react-components';

const getStatusCell = (item: any): JSX.Element => (
    <Avatar sx={{ bgcolor: item?.icon?.web?.style?.backgroundColor }}>
        <CustomIcon
            iconName={item?.icon?.web?.name ?? ''}
            family={item?.icon?.web?.family ?? ''}
            iconProps={{
                sx: {
                    color: item?.icon?.web?.style?.color,
                },
            }}
        />
    </Avatar>
);

const getDaysCell = (data: any): JSX.Element => (
    <Stack>
        <Typography variant="body2" fontSize={'12px'}>
            <b>Within</b>
        </Typography>
        <Typography variant="body2">{data?.within} days</Typography>
    </Stack>
);

const getGroupCell = (data: any): JSX.Element => <GroupPath pathName={data?.groupPath ? data?.groupPath : []} />;

export const UpcomingEvents = (): JSX.Element => {
    const navigate = useNavigate();

    const { deviceId } = useParams();
    const isMount = useIsMount();
    const tableRef: any = React.useRef();

    const currentRealmName = useTypedSelector((state) => state.common.selectedRealm);
    const currentOrg = useTypedSelector(selectedOrg);
    const [searchKey, setSearchKey] = React.useState<any>();

    const [, debouncedValue] = useDebounce(undefined, undefined, searchKey);

    const [payload, setPayload] = React.useState({
        page: 0,
        size: 10,
        filters: {
            // ...(deviceId ? { deviceId: [deviceId] } : { groupId: [selectedNode?.id ?? currentOrg?.id] }),
        },
    });

    const [selectedGroups, setSelectedGroups] = React.useState<any>([]);
    const [groupsData, setGroupsData] = React.useState<any>();
    const [hierarchyMappingData, setHierarchyMappingData] = React.useState({});
    const [loadingHierarchyNode, setLoadingHierarchyNode] = React.useState([]);
    const [getAllGroups, { data: groupsHierarchy }]: any = useGetAllGroupsMutation();

    React.useEffect(() => {
        if (!isMount) {
            setPayload((prev: any) => ({
                ...prev,
                page: 0,
                searchKey: debouncedValue,
            }));
        }
    }, [debouncedValue]);

    React.useEffect(() => {
        if (!isMount && selectedGroups) {
            const filterClone = JSON.parse(JSON.stringify(payload?.filters ?? {}));
            if (selectedGroups?.length) {
                filterClone['groupId'] = selectedGroups;
            } else {
                delete filterClone['groupId'];
            }

            setPayload((prev: any) => ({
                ...prev,
                page: 0,
                filters: { ...filterClone },
            }));
        }
    }, [selectedGroups]);

    React.useEffect(() => {
        if (!groupsData) {
            setGroupsData(groupsHierarchy?.data?.records);
        }
    }, [groupsHierarchy]);

    React.useEffect(() => {
        if (currentOrg?.id) {
            getAllGroups({});
        }
    }, [currentOrg]);

    const handleFetchHierarchy = async (parentData: any): Promise<void> => {
        setHierarchyMappingData({});
        setLoadingHierarchyNode((prevState): any => [...prevState, parentData?.id]);
        const { data: childHierarchyData }: any = await getAllGroups({ parent: parentData?.id });
        if (childHierarchyData) {
            setHierarchyMappingData((prevState) => ({
                ...prevState,
                [parentData?.id]: childHierarchyData?.data?.records,
            }));
        }

        setLoadingHierarchyNode((prevState): any => prevState.filter((loadingNode) => loadingNode !== parentData?.id));
    };
    const groupSelectionHandler = (id: string): void => {
        const payloadClone = JSON.parse(JSON.stringify(selectedGroups));
        const uncheckedIndex = payloadClone?.indexOf(id);
        if (uncheckedIndex !== -1) {
            payloadClone?.splice(uncheckedIndex, 1);
        } else if (id) {
            payloadClone?.push(id);
        }
        setSelectedGroups(payloadClone);
    };

    const handleChipDelete = (key: any): void => {
        tableRef?.current?.resetFilters(key === 'groupId' ? 'groupPath' : key, true);
        const paginationPayloadClone = JSON.parse(JSON.stringify(payload));
        delete paginationPayloadClone?.filters[key];
        setPayload(paginationPayloadClone);
    };

    const getGroupHeaderOptions = (): JSX.Element => (
        <Hierarchy
            isLoading={!groupsData}
            data={groupsData}
            hasChild={(treeItem: any): boolean => treeItem?.groupCount}
            labelKey={'name'}
            hierarchyMappingData={hierarchyMappingData}
            loadingNode={loadingHierarchyNode}
            handleTreeNodeSelection={(parentNode: any): any => {
                groupSelectionHandler(parentNode?.id);
            }}
            filters={[
                {
                    key: 'type',
                    operator: '!==',
                    value: 'DEVICE',
                    action: 'hidden',
                },
                {
                    key: 'type',
                    operator: '!==',
                    value: 'GATEWAY',
                    action: 'hidden',
                },
            ]}
            autoChildSelection={true}
            loadMore={handleFetchHierarchy}
            loadingSkeleton={<GroupHierarchySkeleton row={1} />}
            selectedNodes={selectedGroups}
            selectionType={'checkbox'} // radio, checkbox, node
        />
    );

    const getActionCell = (data: any): JSX.Element => {
        const getLabel = (): any => {
            if (data?.severity === 'Alarm') {
                return <ListItemTag label={'OFFLINE'} fontColor="#DBEEF2" backgroundColor="#69B1C3" />;
            } else if (data?.severity === 'Alarm Cleared') {
                return <ListItemTag label={'CLOSED'} fontColor="#424E54" backgroundColor="#D5D8DA" />;
            } else if (data?.severity === 'Warning Cleared' || data?.status === 'Offline Cleared') {
                return <ListItemTag label={'CLEARED'} fontColor="#424E54" backgroundColor="#D5D8DA" />;
            }
        };

        return (
            <Stack direction={'row'} spacing={2} alignItems={'center'} justifyContent={'end'}>
                {getLabel()}
                <IconButton
                    id="detail-page"
                    onClick={(): void => {
                        navigate(`/${currentRealmName}/UpcomingEventsDetails/${deviceId}/${data?.id} `);
                    }}
                >
                    <ChevronRight />
                </IconButton>
            </Stack>
        );
    };

    const getStatusStyles = (data: any): any => {
        if (data?.severity === 'Alarm' || data?.status?.includes('Active')) {
            return {
                content: '" "',
                position: 'absolute',
                top: '0px',
                bottom: '-1px',
                left: '0px',
                width: '6px',
                zIndex: '100',
                backgroundColor: data?.indicator ?? '#FFFFF',
            };
        }
    };

    const tableRows: Header[] = [
        {
            header: '',
            sx: getStatusStyles,
            cell: (item: any) => getStatusCell(item),
        },
        {
            header: 'Within',
            accessor: 'within',
            isSortable: true,
            isFilterable: true,
            filterOptions: [
                { id: 'all', label: 'All' },
                { id: 30, label: 'Within 30 days' },
                { id: 60, label: 'within 60 days' },
                { id: 90, label: 'within 90 days' },
                { id: 120, label: 'within 120 days' },
            ],
            cell: (data: any) => getDaysCell(data),
        },
        {
            header: 'Type',
            accessor: 'type',
            isSortable: true,
            isFilterable: true,
            isDebounce: true,
        },
        {
            header: 'Device',
            accessor: 'deviceName',
            isSortable: true,
            isFilterable: true,
            isDebounce: true,
        },
        {
            header: 'Group',
            accessor: 'groupId',
            isSortable: true,
            isFilterable: true,
            cell: (data) => getGroupCell(data),
            headerOptions: getGroupHeaderOptions,
        },

        {
            cell: getActionCell,
            header: '',
            // extraOptions: getExtraHeaderOptions,
        },
    ];
    const [tableFilters] = useFilteredValues({
        allFilters: tableRows,
        triggeredFilters: payload?.filters,
    });

    const handleTimelinePageChange = (page: any, size: any): void => {
        setPayload((prev: any) => ({ ...prev, page: page, size: size }));
    };

    const handleFilterChange = (filters: any, sortData?: any): void => {
        if (JSON.stringify(filters) !== JSON.stringify(payload.filters)) {
            setPayload((prev: any) => ({
                ...prev,
                page: 0,
                filters: { ...filters, ...(isMount ? payload.filters : {}) },
            }));
        }
        if (sortData && Object.keys(sortData)?.length) {
            setPayload((prev: any) => ({
                ...prev,
                page: 0,
                sort: sortData,
            }));
        }
    };

    const {
        data: upcomingsData,
        isLoading,
        isFetching,
    }: any = useGetPredictionFiltersQuery(
        {
            body: {
                ...payload,
                filters: tableFilters,
            },
        },
        { skip: !currentOrg?.id, refetchOnMountOrArgChange: true }
    );

    const getChipName = (key: string): string => {
        switch (key) {
            case 'GroupId':
                return 'Group';
            case 'deviceName':
                return 'Device';
            default:
                return toFirstLetterUpperCase(key);
        }
    };

    return (
        <Stack p={3}>
            <Stack direction={'row'} spacing={2} justifyContent={'space-between'} alignItems={'center'}>
                <TextField
                    hiddenLabel
                    id="searchKey"
                    value={searchKey ?? ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                        setSearchKey(e.target.value);
                    }}
                    placeholder="Search"
                    size="small"
                    InputProps={{
                        startAdornment: <Search id="search" sx={{ mr: '5px', color: '#727e84' }} />,
                    }}
                />

                {payload?.filters && (
                    <Stack direction="row" spacing={1}>
                        {Object.keys(payload?.filters).map(
                            (key: string) =>
                                key !== 'deviceId' && (
                                    <Chip
                                        key={`unique${key}`}
                                        label={getChipName(key)}
                                        onDelete={(): void => {
                                            handleChipDelete(key);
                                        }}
                                    />
                                )
                        )}
                    </Stack>
                )}
            </Stack>

            <Paper className="margin-y-16">
                <CustomTable
                    ref={tableRef}
                    data={upcomingsData?.data?.records}
                    isPagination
                    handlePageChange={handleTimelinePageChange}
                    isLoading={isLoading || isFetching}
                    keyToTraverse="id"
                    total={upcomingsData?.data?.total}
                    headers={tableRows}
                    noDataFoundIcon={<TrendingUp fontSize="inherit" />}
                    noDataFoundTitle="No Upcoming Found"
                    handleFilterChange={handleFilterChange}
                />
            </Paper>
        </Stack>
    );
};
