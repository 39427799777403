import { api } from '../../app/api/baseApi';

export const groupApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getAllGroups: builder.mutation<any, any>({
            query: ({ body, headers, parent }) => {
                return {
                    url: `iam/v1/groups/hierarchy`,
                    method: 'POST',
                    body: body ?? {
                        size: 1000,
                        page: 0,
                    },
                    ...(parent && { params: { parent } }),
                    ...(headers && { headers }),
                };
            },
        }),

        getAllGroupsForTariff: builder.mutation<any, any>({
            query: ({ headers, parentId }) => {
                return {
                    url: `ems/v1/assets/hierarchy`,
                    method: 'GET',
                    ...(parentId && { params: { parentId } }),
                    ...(headers && { headers }),
                };
            },
        }),

        getAllFlattenedGroups: builder.query({
            query: (body) => ({
                url: '/iam/v1/groups/details',
                body,
                method: 'POST',
            }),
        }),

        getGroupType: builder.query({
            query: () => ({
                url: '/iam/v1/groups/groupType',
                method: 'GET',
            }),
        }),

        getAssignedUsers: builder.query({
            query: (groupId) => ({
                url: `iam/v1/groups/${groupId}/users`,
                method: 'GET',
            }),
        }),

        createGroup: builder.mutation<object, object>({
            query: (body) => ({
                url: `/iam/v1/groups`,
                method: 'POST',
                body,
            }),
            invalidatesTags: (result, error): any => (!error ? ['Orgs'] : []),
        }),

        moveExistingGroupsAndDevices: builder.mutation<object, object>({
            query: (body) => ({
                url: `/iam/v1/groups`,
                method: 'PATCH',
                body,
            }),
            invalidatesTags: (result, error): any => (!error ? ['GroupById', 'GroupsHierarchy', 'DeviceList'] : []),
        }),
        updateGroup: builder.mutation<object, object>({
            query: (payload: {
                groupId: string;
                groupTypeId: string;
                name: string;
                shortName: string;
                parentId: string;
                lat: any;
                lng: any;
            }) => {
                return {
                    url: `/iam/v1/groups/${payload.groupId}`,
                    method: 'PATCH',
                    body: payload,
                };
            },
            invalidatesTags: (result, error): any => (!error ? ['GroupById'] : []),
        }),
        deleteGroup: builder.mutation({
            query: (id) => ({
                url: `/iam/v1/groups/${id}`,
                method: 'DELETE',
            }),
        }),
        updateGroupBackground: builder.mutation<object, object>({
            query: (payload: { groupId: string; image: any }) => ({
                url: `/iam/v1/groups/${payload.groupId}/image`,
                method: 'PATCH',
                body: payload.image,
            }),
            invalidatesTags: (result, error): any => {
                return !error ? ['GroupById'] : [];
            },
        }),

        getGroupDetails: builder.query({
            query: (id) => ({
                url: `iam/v1/groups/${id}`,
                method: 'GET',
            }),
            providesTags: ['GroupById'],
        }),
        assignUsersToGroups: builder.mutation<object, object>({
            query: (payload: { groupId: string; userIds: string[] }) => ({
                url: `/iam/v1/groups/${payload.groupId}/assign/users`,
                method: 'PATCH',
                body: { userIds: payload.userIds },
            }),
        }),
        removeGroupImage: builder.mutation<object, object>({
            query: (payload: { groupId: string }) => ({
                url: `/iam/v1/groups/${payload.groupId}/image`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error): any => {
                return !error ? ['GroupById'] : [];
            },
        }),

        updateGroupServiceContact: builder.mutation({
            query: (body) => ({
                url: `iam/v1/groups/service-contact`,
                method: 'POST',
                body,
            }),
            invalidatesTags: (result, error): any => {
                return !error ? ['ServiceContact'] : [];
            },
        }),

        getGroupServiceContact: builder.query({
            query: (groupId) => ({
                url: `iam/v1/groups/${groupId}/service-contact`,
                method: 'GET',
            }),
            providesTags: ['ServiceContact'],
        }),
        getFilteredHierarchy: builder.query({
            query: ({ body, headers, parent }) => {
                return {
                    url: `iam/v1/groups/filter`,
                    method: 'POST',
                    body: body ?? {
                        size: 10,
                        page: 0,
                    },
                    ...(parent && { params: { parent } }),
                    ...(headers && { headers }),
                };
            },
        }),
        moveServiceContact: builder.mutation({
            query: ({ body }: any) => ({
                url: 'iam/v1/groups/move/service-contact',
                method: 'PATCH',
                body,
            }),
            invalidatesTags: (result, error): any => {
                return !error ? ['ServiceContact'] : [];
            },
        }),
    }),
});

export const {
    useGetAssignedUsersQuery,
    useGetAllFlattenedGroupsQuery,
    useAssignUsersToGroupsMutation,
    useCreateGroupMutation,
    useUpdateGroupBackgroundMutation,
    useUpdateGroupMutation,
    useGetAllGroupsMutation,
    useGetAllGroupsForTariffMutation,
    useRemoveGroupImageMutation,
    useDeleteGroupMutation,
    useGetGroupDetailsQuery,
    useGetGroupTypeQuery,
    useMoveExistingGroupsAndDevicesMutation,
    useGetGroupServiceContactQuery,
    useGetFilteredHierarchyQuery,
    useUpdateGroupServiceContactMutation,
    useMoveServiceContactMutation,
} = groupApi;
