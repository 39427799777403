import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

const initialState = {
    cloneProfileModal: { payload: {} },
    renameProfileModal: { payload: {} },
    payload: {},
};

const channelManagerSlice = createSlice({
    name: 'channelManager',
    initialState: initialState,
    reducers: {
        resetCloneProfileModal: (state: RootState) => {
            state['cloneChannelModal'] = {
                payload: {},
            };
        },
        resetRenameProfileModal: (state: RootState) => {
            state['renameProfileModal'] = { payload: {} };
        },
        setCloneProfilePayload: (state: RootState, action: PayloadAction<any>) => {
            state['cloneProfileModal']['payload'][action.payload.key] = action.payload.value;
        },
        setRenameProfilePayload: (state: RootState, action: PayloadAction<any>) => {
            state['renameProfileModal']['payload'][action.payload.key] = action.payload.value;
        },
        setPayload: (state: RootState, action: PayloadAction<any>) => {
            state.payload = { ...state.payload, [action?.payload?.key]: action?.payload?.value };
        },
        resetProfile: () => {
            return initialState;
        },
    },
});

export const {
    setPayload,
    resetProfile,
    resetRenameProfileModal,
    setRenameProfilePayload,
    setCloneProfilePayload,
    resetCloneProfileModal,
} = channelManagerSlice.actions;

export const cloneProfileModalData = (state: RootState): any => state.channelManager.cloneProfileModal;
export const renameProfileModalData = (state: RootState): any => state.channelManager.renameProfileModal;

export default channelManagerSlice;
