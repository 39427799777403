import { useDeleteVirtualLoadByIdMutation } from '@fiji/common/src/features/deviceManagement/deviceApi';
import { Stack, Typography } from '@mui/material';
import { CustomModal } from 'components';
import { ModalActions } from 'components/CustomModal';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useConfirm } from '@fiji/common/src/hooks';
import { setMessageContent } from '@fiji/common/src/features/common/commonSlice';
import { useNavigationBack } from 'hooks';

export const DeleteVirtualModal = React.forwardRef((props: any, ref): JSX.Element => {
    const dispatch = useDispatch();
    const navigateBack = useNavigationBack();
    const navigateBackHandler = (): void => {
        navigateBack();
    };

    const [deleteVirtualLoad, { isLoading }] = useDeleteVirtualLoadByIdMutation();
    const submitHandler = async (data: any): Promise<void> => {
        const { error }: any = await deleteVirtualLoad(data?.id);
        if (!error) {
            if (props?.isNavigationBack) navigateBackHandler();
            dispatch(
                setMessageContent({
                    isOpen: true,
                    message: `${data?.name} has been deleted`,
                })
            );
            onCancel();
        }
    };
    const { data: deleteData, isVisible, onCancel, onClick, onConfirm } = useConfirm(submitHandler as any);
    const actions: ModalActions[] = [
        {
            testId: 'cancel',
            key: 'cancel',
            label: 'Cancel',
            close: true,
            variant: 'outlined',
            handleClick: onCancel,
        },
        {
            testId: 'delete',
            key: 'delete',
            label: 'Delete',
            color: 'error',
            className: 'error-btn',
            variant: 'contained',
            disabled: !deleteData?.id || isLoading,
            handleClick: onConfirm,
        },
    ];
    React.useImperativeHandle(ref, () => ({
        onClick: (action: boolean) => onClick(action),
    }));
    return (
        <CustomModal
            isOpen={isVisible}
            isLoading={isLoading}
            type="primary"
            header={`Delete ${deleteData?.name}?`}
            actions={actions}
            hidePadding
            noDividers
            actionsDivider
        >
            <Stack component="form" spacing={2} noValidate autoComplete="off" m={2}>
                <Typography variant="body1">{`Select ‘Delete’ to remove ${deleteData?.name}.`}</Typography>
            </Stack>
        </CustomModal>
    );
});
