import React from 'react';

import { useParams } from 'react-router-dom';
import { useAppDispatch } from '@fiji/common/src/app/store';
import { resetConfiguration } from '@fiji/common/src/features/widgetManagement/detailsWidgetSlice';
import { resetMapConfiguration } from '@fiji/common/src/features/widgetManagement/mapWidgetSlice';
import { useWidgetConfigHandlers } from '../common';
import { resetGaugeConfiguration } from '@fiji/common/src/features/widgetManagement/gaugeWidgetSlice';
import { resetChartConfiguration } from '@fiji/common/src/features/widgetManagement/chartWidgetSlice';
import { resetLoadsConfiguration } from '@fiji/common/src/features/widgetManagement/loadsWidgetSlice';
import { removeWidgetLoadingState } from '@fiji/common/src/features/dashboardManagement/dashboardSlice';
import { resetTrendsConfiguration } from '@fiji/common/src/features/widgetManagement/trendsWidgetSlice';
import { resetWidgetConfiguration } from '@fiji/common/src/features/widgetManagement/widgetConfigurationSlice';
import { resetTimelineConfiguration } from '@fiji/common/src/features/widgetManagement/timelineWidgetSlice';
import { resetGroupListConfiguration } from '@fiji/common/src/features/widgetManagement/groupListWidgetSlice';
import { resetDeviceListConfiguration } from '@fiji/common/src/features/widgetManagement/deviceListWidgetSlice';
import { resetVirtualLoadConfiguration } from '@fiji/common/src/features/widgetManagement/virtualLoadsWidgetSlice';
import { setAppHeaderSubTitle, setPageHeaderData } from '@fiji/common/src/features/common/commonSlice';
import { WidgetConfigurationHeader, WidgetConfigurationBody } from './';
import { resetCommandBarConfiguration } from '@fiji/common/src/features/widgetManagement/commandBarWidgetSlice';
import { resetScheduleConfiguration } from '@fiji/common/src/features/widgetManagement/scheduleWidgetSlice';
import { resetUpcomingConfiguration } from '@fiji/common/src/features/widgetManagement/upcomingWidgetSlice';
import { resetComponentConfiguration } from '@fiji/common/src/features/widgetManagement/componentWidgetSlice';
import { resetScoreConfiguration } from '@fiji/common/src/features/widgetManagement/scoreWidgetSlice';
import { resetConsumptionConfiguration } from '@fiji/common/src/features/widgetManagement/consumptionBreakdownWidgetSlice';
import { resetLoadTrendsConfiguration } from '@fiji/common/src/features/widgetManagement/loadTrendsWidgetSlice';
import { resetEnergyFlowWidget } from '@fiji/common/src/features/widgetManagement/energyFlowWidgetSlice';
import { resetMetricsConfiguration } from '@fiji/common/src/features/widgetManagement/metricsWidgetSlice';
import { resetWeatherConfiguration } from '@fiji/common/src/features/widgetManagement/weatherWidgetSlice';
import { resetAggregatedTrendsConfiguration } from '@fiji/common/src/features/widgetManagement/aggregatedTrendsWidgetSlice';

export const WidgetConfiguration = (): JSX.Element => {
    const { widgetId } = useParams();
    const dispatch = useAppDispatch();

    const {
        details: { widgetDetails },
        predefinedHandlers,
    } = useWidgetConfigHandlers();

    React.useEffect(() => {
        dispatch(removeWidgetLoadingState(widgetId));
    }, []);

    React.useEffect(() => {
        if (widgetDetails?.config) {
            predefinedHandlers(widgetDetails?.config);
        }
    }, [widgetDetails]);

    React.useEffect(
        () => () => {
            dispatch(resetWidgetConfiguration());
            dispatch(resetConfiguration());
            dispatch(resetGaugeConfiguration());
            dispatch(resetChartConfiguration());
            dispatch(resetDeviceListConfiguration());
            dispatch(resetTimelineConfiguration());
            dispatch(resetUpcomingConfiguration());
            dispatch(resetComponentConfiguration());
            dispatch(resetScoreConfiguration());
            dispatch(resetVirtualLoadConfiguration());
            dispatch(resetTrendsConfiguration());
            dispatch(resetGroupListConfiguration());
            dispatch(resetMapConfiguration());
            dispatch(resetLoadsConfiguration());
            dispatch(resetConsumptionConfiguration());
            dispatch(resetMetricsConfiguration());
            dispatch(setPageHeaderData({}));
            dispatch(resetVirtualLoadConfiguration());
            dispatch(resetCommandBarConfiguration());
            dispatch(setAppHeaderSubTitle(undefined));
            dispatch(resetScheduleConfiguration());
            dispatch(resetLoadTrendsConfiguration());
            dispatch(resetEnergyFlowWidget());
            dispatch(resetWeatherConfiguration());
            dispatch(resetAggregatedTrendsConfiguration());
        },
        []
    );

    return (
        <>
            <WidgetConfigurationHeader />
            <WidgetConfigurationBody />
        </>
    );
};
