import React from 'react';

import { TextField, Stack, FormHelperText } from '@mui/material';
import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import { setUpdateProperties } from '@fiji/common/src/features/deviceManagement/deviceSlice';
import { t } from 'i18next';

export const NameOrDescriptionModal = ({ type }: { type: string }): JSX.Element => {
    const dispatch = useAppDispatch();
    const { propertiesPayload: payload } = useTypedSelector((state) => state.device);
    const maxLength = type === 'name' ? 32 : 256;
    return (
        <>
            <TextField
                fullWidth
                name={type}
                label={type === 'name' ? t('COMMON:NAME') : t('COMMON:DESCRIPTION')}
                multiline
                inputProps={{ maxLength }}
                value={payload[type]}
                onChange={(e: any): void =>
                    dispatch(setUpdateProperties({ key: e.target.name, value: e.target.value }))
                }
                variant="filled"
                className="margin-top-18"
                {...(type === 'name' && { required: true })}
                id="outlined-required"
            />
            <Stack direction={'row'} justifyContent={'flex-end'} px={2}>
                <FormHelperText>{`${payload?.[type]?.length ?? 0}/${maxLength}`}</FormHelperText>
            </Stack>
        </>
    );
};
