import React from 'react';
import { Stack, Typography, Paper, Switch, Container, Button, FormControl, Select, MenuItem } from '@mui/material';

import Remove from '@mui/icons-material/Remove';
import Add from '@mui/icons-material/Add';
import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import { setDisplayPreferences } from '@fiji/common/src/features/widgetManagement/detailsWidgetSlice';
import { useGetChannelValues, useWidgetConfigHandlers } from './hooks';
import { useTheme } from '@mui/material/styles';

export const DisplayPreferences = (props: any): JSX.Element => {
    const theme: any = useTheme();

    const preferencesPayload = useTypedSelector((state) => state.details);
    const dispatch = useAppDispatch();
    const {
        details: { widgetDetails },
    } = useWidgetConfigHandlers();

    useGetChannelValues(
        widgetDetails?.widgetType?.id === 'settings' || widgetDetails?.widgetType?.id === 'properties'
            ? 'details'
            : widgetDetails?.widgetType?.id
    );

    return (
        <Paper className="padding-16">
            <Container className="bg-content-wrapper min-height-828 padding-16">
                <Stack className="bg-white">
                    <Stack
                        direction={'row'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        p={2}
                        className="border-bottom-1"
                    >
                        <Typography variant="subtitle2" fontSize={'14px'} fontWeight={'600'}>
                            {widgetDetails?.widgetType?.id === 'settings'
                                ? 'Display total count in header'
                                : `Display total ${props?.countText ?? 'count'} in header`}
                        </Typography>
                        <Switch
                            sx={{
                                '& .MuiSwitch-switchBase.Mui-checked': {
                                    color: theme?.palette?.primary?.main,
                                    '& + .MuiSwitch-track': {
                                        backgroundColor: theme?.palette?.primary?.main,
                                    },
                                },
                                '& .MuiSwitch-switchBase.Mui-checked.Mui-disabled': {
                                    color: theme?.palette?.primary?.[50],
                                    '& + .MuiSwitch-track': {
                                        backgroundColor: theme?.palette?.primary?.[200],
                                    },
                                },
                            }}
                            checked={preferencesPayload?.isTotalCountVisible}
                            onChange={(): void => {
                                dispatch(
                                    setDisplayPreferences({
                                        key: 'isTotalCountVisible',
                                        value: !preferencesPayload?.isTotalCountVisible,
                                    })
                                );
                            }}
                        />
                    </Stack>
                    {props?.countText && (
                        <Stack
                            direction={'row'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            py={1}
                            px={2}
                            className="border-bottom-1"
                        >
                            <Typography variant="subtitle2" fontWeight={'600'}>
                                {`Number of ${props?.countText ?? ''}  to display`}
                            </Typography>
                            <FormControl className="min-width-250 ">
                                <Select
                                    className="custom-select"
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    variant="filled"
                                    defaultValue={preferencesPayload?.countMode}
                                    onChange={(e: any): void => {
                                        dispatch(setDisplayPreferences({ key: 'countMode', value: e.target.value }));
                                        if (e.target.value === 'all')
                                            dispatch(setDisplayPreferences({ key: 'secondaryChannelCount', value: 6 }));
                                    }}
                                >
                                    <MenuItem value={'all'}>All</MenuItem>
                                    <MenuItem value={'custom'}>Custom</MenuItem>
                                </Select>
                            </FormControl>
                        </Stack>
                    )}

                    {(preferencesPayload?.countMode === 'custom' || widgetDetails?.widgetType?.id === 'details') && (
                        <Stack
                            direction={'row'}
                            p={2}
                            className="border-bottom-1"
                            justifyContent={'space-between'}
                            alignItems={'center'}
                        >
                            <Typography variant="subtitle2" fontSize={'14px'} fontWeight={'600'} className="black-700">
                                {`Maximum number of ${props?.countText ?? 'secondary channels'} to display.`}
                            </Typography>
                            <Stack direction={'row'} alignItems={'center'}>
                                <Button
                                    variant="text"
                                    color="inherit"
                                    className="height-46 width-46 bg-content-wrapper"
                                    disabled={preferencesPayload?.secondaryChannelCount === 1}
                                    onClick={(): void => {
                                        dispatch(
                                            setDisplayPreferences({
                                                key: 'secondaryChannelCount',
                                                value: preferencesPayload?.secondaryChannelCount - 1,
                                            })
                                        );
                                    }}
                                >
                                    <Remove className="content-color" />
                                </Button>
                                <Typography px={3} variant="body1" fontWeight={'600'} textAlign={'center'}>
                                    {preferencesPayload?.secondaryChannelCount}
                                </Typography>
                                <Button
                                    variant="text"
                                    color="inherit"
                                    className="height-46 width-46 bg-content-wrapper"
                                    disabled={preferencesPayload?.secondaryChannelCount === 30}
                                    onClick={(): void => {
                                        dispatch(
                                            setDisplayPreferences({
                                                key: 'secondaryChannelCount',
                                                value: preferencesPayload?.secondaryChannelCount + 1,
                                            })
                                        );
                                    }}
                                >
                                    <Add className="content-color" />
                                </Button>
                            </Stack>
                        </Stack>
                    )}

                    <Stack direction={'row'} p={2} justifyContent={'space-between'} alignItems={'center'}>
                        <Typography variant="subtitle2" fontSize={'14px'} fontWeight={'600'}>
                            Display “View All” bar
                        </Typography>
                        <Switch
                            sx={{
                                '& .MuiSwitch-switchBase.Mui-checked': {
                                    color: theme?.palette?.primary?.main,
                                    '& + .MuiSwitch-track': {
                                        backgroundColor: theme?.palette?.primary?.main,
                                    },
                                },
                                '& .MuiSwitch-switchBase.Mui-checked.Mui-disabled': {
                                    color: theme?.palette?.primary?.[50],
                                    '& + .MuiSwitch-track': {
                                        backgroundColor: theme?.palette?.primary?.[200],
                                    },
                                },
                            }}
                            checked={preferencesPayload?.viewAllButton}
                            onChange={(): void => {
                                dispatch(
                                    setDisplayPreferences({
                                        key: 'viewAllButton',
                                        value: !preferencesPayload?.viewAllButton,
                                    })
                                );
                            }}
                        />
                    </Stack>
                </Stack>
            </Container>
        </Paper>
    );
};
