import React from 'react';

import { FormControl, InputLabel, Select, Typography } from '@mui/material';
import { CustomTransComponent, Hierarchy, renderGroupHierarchyIcons } from 'components';
import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import { selectedOrg } from '@fiji/common/src/features/orgManagement/orgSlice';
import useStyles from 'pages/AddDevice/styles';
import { useTheme } from '@mui/material/styles';
import { setUpdateProperties } from '@fiji/common/src/features/deviceManagement/deviceSlice';
import { useGetAllGroupsMutation } from '@fiji/common/src/features/group/groupApi';

export const ModalHierarchy = (): JSX.Element => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const [groupData, setGroupData] = React.useState();
    const dispatch = useAppDispatch();
    const [hierarchyMappingData, setHierarchyMappingData] = React.useState<{ [key: string]: any }>({});
    const [loadingHierarchyNode, setLoadingHierarchyNode] = React.useState([]);
    const [openSelectBox, setOpenSelectBox] = React.useState(false);

    const [getHierarchyData, { data: hierarchyData, isLoading: hierarchyDataLoader }] = useGetAllGroupsMutation({
        fixedCacheKey: 'cachedAllGroupData',
    });

    React.useEffect(() => {
        if (!groupData) {
            setGroupData(hierarchyData?.data?.records);
        }
    }, [hierarchyData]);

    const { propertiesPayload } = useTypedSelector((state) => state.device);

    const handleFetchHierarchy = async (parentData: any): Promise<void> => {
        setLoadingHierarchyNode((prevState): any => [...prevState, parentData?.id]);

        const { data: childHierarchyData }: any = await getHierarchyData({ parent: parentData?.id });

        if (childHierarchyData) {
            setHierarchyMappingData((prevState) => ({
                ...prevState,
                [parentData?.id]: childHierarchyData?.data?.records,
            }));
        }

        setLoadingHierarchyNode((prevState): any => prevState.filter((loadingNode) => loadingNode !== parentData?.id));
    };

    const handleToggleSelectBox = (): void => {
        setOpenSelectBox((prev) => {
            if (!prev === false) {
                setHierarchyMappingData({});
            }
            return !prev;
        });
    };

    const handleCleanHierarchyNode = (parentId: string): void => {
        setHierarchyMappingData((prevState) => {
            const newHierarchyMappingData: any = { ...prevState };
            delete newHierarchyMappingData[parentId];
            return newHierarchyMappingData;
        });
    };

    const currentOrg = useTypedSelector(selectedOrg);
    return (
        <FormControl variant={'filled'} className={classes.formControl} required fullWidth>
            <InputLabel id={'select-Assign-to-Group'} required={false}>
                <CustomTransComponent translationKey={'COMMON:GROUP'} />
            </InputLabel>
            <Select
                displayEmpty
                id="groupId"
                labelId="demo-customized-select-label"
                disabled={hierarchyDataLoader}
                renderValue={(): any => (
                    <Typography sx={{ display: 'flex', alignContent: 'center', py: '8px' }}>
                        <span>{propertiesPayload['groupId']?.name}</span>
                    </Typography>
                )}
                value={propertiesPayload['groupId']?.name ? ' ' : ''}
                open={openSelectBox}
                onClose={handleToggleSelectBox}
                onOpen={handleToggleSelectBox}
            >
                <Hierarchy
                    labelKey="name"
                    data={
                        currentOrg?.defaultGroupAccess
                            ? [
                                  {
                                      id: currentOrg?.id,
                                      name: `${currentOrg?.name} (Organization Root)`,
                                      icon: {
                                          web: { family: 'material-ui', name: 'CorporateFare' },
                                      },
                                      groupCount: currentOrg?.groupCount,
                                      deviceCount: currentOrg?.deviceCount,
                                  },
                              ]
                            : groupData
                    }
                    loadMore={handleFetchHierarchy}
                    cleanHiearchyNode={handleCleanHierarchyNode}
                    hierarchyMappingData={hierarchyMappingData}
                    loadingNode={loadingHierarchyNode}
                    selectionType={'radio'}
                    selectedNodes={propertiesPayload['groupId']?.id}
                    hasChild={(treeItem): boolean => treeItem?.groupCount}
                    getAvatarChild={renderGroupHierarchyIcons}
                    handleTreeNodeSelection={(node: any): void => {
                        dispatch(
                            setUpdateProperties({
                                key: 'groupId',
                                value: {
                                    id: node.id,
                                    name: node.name,
                                    destinationGroupIcon: node.icon,
                                },
                            })
                        );
                        handleToggleSelectBox();
                    }}
                    filters={[
                        {
                            key: 'type',
                            operator: '!==',
                            value: 'DEVICE',
                            action: 'hidden',
                        },
                        {
                            key: 'type',
                            operator: '!==',
                            value: 'GATEWAY',
                            action: 'hidden',
                        },
                    ]}
                />
            </Select>
        </FormControl>
    );
};
