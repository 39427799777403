import React, { useCallback } from 'react';
import {
    ClickAwayListener,
    Divider,
    IconButton,
    List,
    ListItemButton,
    ListItemText,
    Paper,
    Stack,
    Typography,
} from '@mui/material';
import { UserMenu } from '@brightlayer-ui/react-components';
import Avatar from '@mui/material/Avatar';
import Settings from '@mui/icons-material/Settings';
import Input from '@mui/icons-material/Input';
import Group from '@mui/icons-material/Group';
import AccountTree from '@mui/icons-material/AccountTree';
import SmartToy from '@mui/icons-material/SmartToy';
import Dashboard from '@mui/icons-material/Dashboard';
import TableChart from '@mui/icons-material/TableChart';
import NotificationsPaused from '@mui/icons-material/NotificationsPaused';
import Snooze from '@mui/icons-material/Snooze';
import Help from '@mui/icons-material/Help';
import Policy from '@mui/icons-material/Policy';
import Person from '@mui/icons-material/Person';

import { selectedOrg } from '@fiji/common/src/features/orgManagement/orgSlice';
import makeStyles from '@mui/styles/makeStyles';
import { useTypedSelector } from '@fiji/common/src/app/store';
import { getUserInitials } from '../utils/helpers';
import { useOktaAuth } from '@okta/okta-react';
import { Theme, useTheme } from '@mui/material/styles';

import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { CustomTransComponent, NeedHelpScreen, PrivacyPolicy } from './';
import { useGetUserProfileQuery } from '@fiji/common/src/features/profile/profileApi';
import { ApiEndpointType, ApiResponseType } from '@fiji/common/src/features/common/commonTypes';
import { Profile } from '@fiji/common/src/types';
import { DeviceConfiguration, PushNotificationSolid } from '@brightlayer-ui/icons-mui';
import * as Colors from '@brightlayer-ui/colors';
import { useRBAC } from '../hooks';
import { selectCurrentPermission, selectGlobalPermissions } from '@fiji/common/src/features/profile/profileSlice';
import { useCountdownTimer } from 'hooks/useCountdownTimer';
import CreateSnoozeModal from './CreateSnoozeModal';
import { MonetizationOn } from '@mui/icons-material';

const useStyles = makeStyles((theme: Theme) => ({
    toolbarRightItem: {
        marginRight: theme.spacing(2),
        marginLeft: '11px',
    },
    headerMenuListItem: {
        '& .BluiInfoListItem-root': {
            padding: '9px 16px',
        },
    },
}));

type HeaderActionsProps = {
    disabled?: boolean;
    isSystemAdmin?: boolean;
    isUpcomingServicePortal?: boolean;
};

/* The above code is defining a React functional component called `HeaderActions`. It is exporting this
component as a named export. The component is wrapped in the `React.memo` function, which is used
for performance optimization by memoizing the component and preventing unnecessary re-renders. */

export const HeaderActions: React.FC<HeaderActionsProps> = React.memo(
    ({ disabled, isSystemAdmin, isUpcomingServicePortal }): JSX.Element => {
        const theme: any = useTheme();
        const { t } = useTranslation();
        const navigate = useNavigate();
        const classes = useStyles(theme);
        const { oktaAuth } = useOktaAuth();
        const policyRef = React.useRef<any>(null);
        const currentOrg = useTypedSelector(selectedOrg);
        const currentRealmName = useTypedSelector((state) => state.common.selectedRealm);
        const isSuperRealm = useTypedSelector((state) => state.common.isSuperRealm);
        const isAdminRealm = useTypedSelector((state) => state.common.isAdminRealm);
        const timerString = useTypedSelector((state) => state.alarmtimeline.timerString);
        const permissions = useTypedSelector(selectCurrentPermission);
        const globalPermissions = useTypedSelector(selectGlobalPermissions);
        const { hasPermission } = useRBAC(globalPermissions?.length ? globalPermissions : permissions);
        const canViewGroups = hasPermission('view-group-management');
        const canViewSystemDashboards = hasPermission('view-system-dashboards');
        const canViewUserDashboards = hasPermission('view-user-dashboards');
        const canViewSystemWidgets = hasPermission('view-system-widgets');
        const canViewUserWidgets = hasPermission('view-user-widgets');
        const canEditDevices = hasPermission('edit-devices');
        const canViewUser = hasPermission('view-users');
        const canViewRole = hasPermission('view-roles');
        const canCreateAutomation = hasPermission('create-automations');
        const canCreateUser = hasPermission('create-users');
        const canCreateGroup = hasPermission('create-groups');
        const canViewLogs = hasPermission('view-logs');
        const canViewAutomations = hasPermission('view-automations');
        const canSnooze = hasPermission('snooze-devices');
        const { deviceId } = useParams();

        const snoozeRef: any = React.useRef(null);

        const { data } = useGetUserProfileQuery({}, { skip: !currentRealmName }) as ApiEndpointType<
            ApiResponseType<Profile>
        >;

        const [openUnSnooze, setOpenUnSnooze] = React.useState(false);
        const [isHelpScreenVisible, setIsHelpScreenVisible] = React.useState(false);

        const handleClickAway = (): void => {
            setOpenUnSnooze(false);
        };

        const time: any = useCountdownTimer(deviceId ?? '', true);

        const handleUserLogout = useCallback(async (): Promise<void> => {
            await oktaAuth.signOut();
            if (window.localStorage.getItem('tempLocale')) window.localStorage.removeItem('tempLocale');
        }, []);

        const getMenuItems = (): any => {
            const menuItem: any[] = [
                {
                    title: (<CustomTransComponent translationKey={'COMMON:ORG_LABEL'} />) as any,
                    icon: (
                        <Avatar
                            sx={{
                                backgroundColor: theme?.palette?.primary?.[50],
                                color: theme?.palette?.primary?.main,
                            }}
                            alt={currentOrg?.name}
                            src={currentOrg?.logo ?? ''}
                        >
                            {currentOrg?.name && getUserInitials(currentOrg?.name)}
                        </Avatar>
                    ),
                    subtitle: currentOrg?.orgCode,
                    chevron: true,
                    divider: true,
                    onClick: () => navigate(`/${currentRealmName}/organization`),
                },
                ...(canViewGroups || canCreateGroup
                    ? [
                          {
                              title: (
                                  <CustomTransComponent
                                      translationKey={'COMPONENTS:HEADER_ACTIONS.MENU_ITEM_TEXTS.GROUPS_TITLE'}
                                  />
                              ),
                              icon: (
                                  <Avatar sx={{ backgroundColor: theme?.palette?.primary?.main }}>
                                      <AccountTree className="text-white" />
                                  </Avatar>
                              ),
                              subtitle: (
                                  <CustomTransComponent
                                      translationKey={'COMPONENTS:HEADER_ACTIONS.MENU_ITEM_TEXTS.GROUPS_SUBTITLE'}
                                  />
                              ),
                              chevron: true,
                              divider: true,
                              onClick: (): void => navigate(`/${currentRealmName}/groups`),
                          },
                      ]
                    : []),
                {
                    title: (
                        <CustomTransComponent
                            translationKey={'COMPONENTS:HEADER_ACTIONS.MENU_ITEM_TEXTS.NOTIFICATIONS_TITLE'}
                        />
                    ),
                    icon: (
                        <Avatar
                            sx={{
                                backgroundColor: theme?.palette?.primary?.[50],
                                color: theme?.palette?.primary?.main,
                            }}
                        >
                            <PushNotificationSolid />
                        </Avatar>
                    ),

                    subtitle: (
                        <CustomTransComponent
                            translationKey={'COMPONENTS:HEADER_ACTIONS.MENU_ITEM_TEXTS.NOTIFICATIONS_SUBTITLE'}
                        />
                    ),
                    chevron: true,
                    divider: true,
                    onClick: () => navigate(`/${currentRealmName}/notifications`),
                },

                ...(canViewAutomations || canCreateAutomation
                    ? [
                          {
                              title: (
                                  <CustomTransComponent
                                      translationKey={'COMPONENTS:HEADER_ACTIONS.MENU_ITEM_TEXTS.AUTOMATION_TITLE'}
                                  />
                              ) as any,
                              icon: (
                                  <Avatar sx={{ background: Colors.gray[500] }}>
                                      <SmartToy className="gray-50" />
                                  </Avatar>
                              ),
                              subtitle: (
                                  <CustomTransComponent
                                      translationKey={'COMPONENTS:HEADER_ACTIONS.MENU_ITEM_TEXTS.AUTOMATION_SUBTITLE'}
                                  />
                              ) as any,
                              chevron: true,
                              divider: true,
                              onClick: () => navigate(`/${currentRealmName}/automations`),
                          },
                      ]
                    : []),
                ...(canViewUser || canViewRole || canCreateUser
                    ? [
                          {
                              title: <CustomTransComponent translationKey={'COMMON:USERS'} />,
                              icon: (
                                  <Avatar sx={{ backgroundColor: theme?.palette?.primary?.main }}>
                                      <Group className="text-white" />
                                  </Avatar>
                              ),
                              subtitle: (
                                  <CustomTransComponent
                                      translationKey={'COMPONENTS:HEADER_ACTIONS.MENU_ITEM_TEXTS.USER_SUBTITLE'}
                                  />
                              ),
                              chevron: true,
                              divider: true,
                              onClick: (): void => navigate(`/${currentRealmName}/usersAndRoles`),
                          },
                      ]
                    : []),
                ...(canViewLogs
                    ? [
                          {
                              title: <CustomTransComponent translationKey={'COMMON:AUDIT_LOGS'} />,
                              icon: (
                                  <Avatar
                                      sx={{
                                          backgroundColor: theme?.palette?.primary?.[50],
                                          color: theme?.palette?.primary?.main,
                                      }}
                                  >
                                      <TableChart />
                                  </Avatar>
                              ),
                              subtitle: (
                                  <CustomTransComponent
                                      translationKey={'COMPONENTS:HEADER_ACTIONS.MENU_ITEM_TEXTS.AUDIT_SUBTITLE'}
                                  />
                              ),
                              chevron: true,
                              divider: true,
                              ...(!disabled && { onClick: () => navigate(`/${currentRealmName}/auditLogs`) }),
                          },
                      ]
                    : []),
                ...(canViewSystemWidgets || canViewUserWidgets || canViewSystemDashboards || canViewUserDashboards
                    ? [
                          {
                              title: (
                                  <CustomTransComponent
                                      translationKey={'COMPONENTS:HEADER_ACTIONS.MENU_ITEM_TEXTS.DASHBOARD_TITLE'}
                                  />
                              ),
                              icon: (
                                  <Avatar className="bg-gray-200 color-content">
                                      <Dashboard />
                                  </Avatar>
                              ),

                              subtitle: (
                                  <CustomTransComponent
                                      translationKey={'COMPONENTS:HEADER_ACTIONS.MENU_ITEM_TEXTS.DASHBOARD_SUBTITLE'}
                                  />
                              ),
                              chevron: true,
                              divider: true,
                              onClick: (): void => navigate(`/${currentRealmName}/dashboardWidget`),
                          },
                      ]
                    : []),
                ...[
                    {
                        title: (
                            <CustomTransComponent
                                translationKey={'COMPONENTS:HEADER_ACTIONS.MENU_ITEM_TEXTS.CHANNEL_LIBRARY_TITLE'}
                            />
                        ),
                        icon: (
                            <Avatar
                                sx={{
                                    backgroundColor: theme?.palette?.primary?.[50],
                                    color: theme?.palette?.primary?.main,
                                }}
                            >
                                <DeviceConfiguration />
                            </Avatar>
                        ),

                        subtitle: (
                            <CustomTransComponent
                                translationKey={'COMPONENTS:HEADER_ACTIONS.MENU_ITEM_TEXTS.CHANNEL_LIBRARY_SUBTITLE'}
                            />
                        ),
                        chevron: true,
                        divider: true,
                        onClick: (): void => navigate(`/${currentRealmName}/channelLibrary`),
                    },
                ],
                ...(canViewSystemDashboards || canViewUserDashboards
                    ? [
                          {
                              title: (
                                  <CustomTransComponent
                                      size={{ width: '100%' }}
                                      translationKey={
                                          'COMPONENTS:HEADER_ACTIONS.MENU_ITEM_TEXTS:TARIFFS_AND_CURRENCIES'
                                      }
                                  />
                              ),
                              icon: (
                                  <Avatar className="bg-gray-200 color-content">
                                      <MonetizationOn />
                                  </Avatar>
                              ),

                              subtitle: (
                                  <CustomTransComponent
                                      size={{ width: '100%' }}
                                      translationKey={
                                          'COMPONENTS:HEADER_ACTIONS.MENU_ITEM_TEXTS:TARIFFS_AND_CURRENCIES_SUBTITLE'
                                      }
                                  />
                              ),
                              chevron: true,
                              divider: true,
                              onClick: (): void => navigate(`/${currentRealmName}/tariffsAndCurrencies`),
                          },
                      ]
                    : []),
            ];

            menuItem.push({
                title: '',
                subtitle: `v${process.env.REACT_APP_VERSION ?? ''}.${window.APP_CONFIG.BUILD_NUMBER}`,
                className: 'build-version-text',
            });

            return menuItem;
        };

        return (
            <>
                {!isSystemAdmin && !isSuperRealm && !isAdminRealm ? (
                    <UserMenu
                        mr={1}
                        id="need-help"
                        MenuProps={{
                            style: {
                                top: '16px',
                            },
                            sx: {
                                '& .MuiButtonBase-root ': {
                                    padding: '9px 16px',
                                },
                                '& .MuiListItem-root': {
                                    height: 'auto',
                                },
                                '& .BluiInfoListItem-title': {
                                    fontWeight: '600 !important',
                                    lineHeight: '22px',
                                    color: '#424E54',
                                },
                            },
                        }}
                        avatar={
                            <IconButton data-cy={'need-help'} color={'inherit'} size="large">
                                <Help />
                            </IconButton>
                        }
                        menuGroups={[
                            {
                                items: [
                                    {
                                        title: t('bluiRegistration:MESSAGES.CONTACT'),
                                        icon: <Help />,
                                        onClick: (): void => setIsHelpScreenVisible(true),
                                    },
                                    {
                                        title: t('bluiRegistration:MESSAGES.PRIVACY'),
                                        icon: <Policy />,
                                        onClick: (): void => policyRef?.current?.modalHandler(true),
                                    },
                                ],
                            },
                        ]}
                    />
                ) : (
                    <></>
                )}

                <CreateSnoozeModal ref={snoozeRef} deviceId={deviceId} />

                {deviceId && canEditDevices && canSnooze && window?.location?.pathname?.includes('device') && (
                    <Stack direction={'row'} alignItems={'center'}>
                        <Stack direction={'row'} alignItems={'center'}>
                            {!time?.isVisible ? (
                                <IconButton
                                    color={'inherit'}
                                    size="large"
                                    id="snooze-button"
                                    onClick={(): void => snoozeRef?.current?.onClick(true)}
                                >
                                    <NotificationsPaused />
                                </IconButton>
                            ) : (
                                <Stack direction={'row'} alignItems={'center'} mr={2}>
                                    <IconButton
                                        id="unsnooze-button"
                                        color={'inherit'}
                                        size="large"
                                        onClick={(): void => setOpenUnSnooze((prev) => !prev)}
                                    >
                                        <Snooze />
                                    </IconButton>
                                    <Typography variant="caption" fontSize={'12px'}>
                                        {timerString}
                                    </Typography>

                                    {openUnSnooze && (
                                        <ClickAwayListener onClickAway={handleClickAway}>
                                            <Paper className="drop-down-menu">
                                                <List disablePadding>
                                                    <ListItemButton
                                                        id="unsnooze"
                                                        onClick={(): void => {
                                                            snoozeRef?.current?.submitHandler(false);
                                                            setOpenUnSnooze(false);
                                                        }}
                                                    >
                                                        <ListItemText primary="Unsnooze" />
                                                    </ListItemButton>
                                                </List>
                                            </Paper>
                                        </ClickAwayListener>
                                    )}
                                </Stack>
                            )}
                        </Stack>
                        <Divider orientation="vertical" className="custom-divider" />
                    </Stack>
                )}
                {!isSystemAdmin && !disabled && !isSuperRealm && !isAdminRealm && (
                    <UserMenu
                        className={classes.toolbarRightItem}
                        id="users"
                        MenuProps={{
                            style: {
                                top: '16px',
                            },
                            sx: {
                                '& .MuiButtonBase-root ': {
                                    padding: '9px 16px',
                                    height: '64px !important',
                                },
                                '& .MuiListItem-root': {
                                    height: 'auto',
                                },
                                '& .BluiInfoListItem-title': {
                                    fontWeight: '600 !important',
                                    lineHeight: '22px',
                                    color: ' #424E54',
                                },
                            },
                        }}
                        avatar={
                            <IconButton color={'inherit'} size="large" data-cy={'settings'}>
                                <Settings />
                            </IconButton>
                        }
                        menuGroups={[
                            {
                                items: getMenuItems(),
                            },
                        ]}
                    />
                )}
                {!isUpcomingServicePortal && (
                    <UserMenu
                        id="profile-menu"
                        MenuProps={{
                            style: {
                                top: '16px',
                                width: '25%',
                            },
                        }}
                        avatar={
                            <Avatar
                                src={data?.data?.image || ''}
                                sx={{
                                    color: theme?.palette?.primary?.main,
                                    backgroundColor: theme?.palette?.primary?.[50],
                                }}
                            >
                                {data?.data?.firstName || data?.data?.lastName ? (
                                    getUserInitials(`${data?.data?.firstName || ' '} ${data?.data?.lastName || ''}`)
                                ) : (
                                    <Person fontSize="inherit" />
                                )}
                            </Avatar>
                        }
                        menuGroups={[
                            {
                                items: [
                                    {
                                        title: (<CustomTransComponent translationKey={'COMMON:MY_PROFILE'} />) as any,
                                        icon: <Person />,
                                        onClick: (): void => {
                                            if (isSystemAdmin) {
                                                navigate(`/admin/myProfile`);
                                            } else {
                                                navigate(`/${currentRealmName}/myProfile`);
                                            }
                                        },
                                    },
                                    {
                                        title: (
                                            <CustomTransComponent
                                                translationKey={'COMPONENTS:HEADER_ACTIONS.LOG_OUT_LABEL'}
                                            />
                                        ) as any,
                                        icon: <Input />,
                                        onClick: handleUserLogout as any,
                                    },
                                ],
                            },
                        ]}
                        menuTitle={`${data?.data?.firstName || '-'} ${data?.data?.lastName || '-'}`}
                        menuSubtitle={`${data?.data?.email || '-'}`}
                    />
                )}

                <NeedHelpScreen
                    isOpen={isHelpScreenVisible}
                    handleCloseModal={(): void => setIsHelpScreenVisible(false)}
                />
                <PrivacyPolicy ref={policyRef} />
            </>
        );
    }
);
