/* eslint-disable @typescript-eslint/no-floating-promises */
import React from 'react';
import { useGetAllWidgetTypesQuery, useGetAllWidgetsQuery } from '@fiji/common/src/features/widgetManagement/widgetApi';
import { CustomTable, DateAndYearSkelton, CustomTransComponent } from '../../../components';
import { Header } from '@fiji/common/src/types';
import { Avatar, Chip, IconButton, Paper, Skeleton, Stack, Typography } from '@mui/material';
import ChevronRight from '@mui/icons-material/ChevronRight';
import ContentCopy from '@mui/icons-material/ContentCopy';
import DashboardCustomize from '@mui/icons-material/DashboardCustomize';
import DeleteForever from '@mui/icons-material/DeleteForever';
import DriveFileRenameOutline from '@mui/icons-material/DriveFileRenameOutline';
import Edit from '@mui/icons-material/Edit';
import { useTypedSelector } from '@fiji/common/src/app/store';
import { useNavigate, useParams } from 'react-router-dom';
import { useFilteredValues } from 'hooks/useFilteredValues';
import { toFirstLetterUpperCase } from 'utils/helpers';
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { selectedOrg } from '@fiji/common/src/features/orgManagement/orgSlice';
import { useDebounce, useIsMount } from '@fiji/common/src/hooks';
import CustomIcon from 'components/CustomIcon';
import { CustomMenu } from 'components/CustomMenu';
import { DeleteWidgetModal, CloneWidgetModal, RenameWidgetModal } from './';
import { selectCurrentPermission } from '@fiji/common/src/features/profile/profileSlice';
import { useRBAC } from 'hooks';
import { useTransformData } from 'hooks/useTransformData';
import { useGetDeviceModelsByCategoryMutation } from '@fiji/common/src/features/deviceManagement/ontologyApi';
import { InputWithCustomPlaceholder } from 'components/InputWithCustomPlaceholder';
import { getWidgetTypeValidationConfig } from '@fiji/common/src/utils/helpers';

const getNavigateCell = (
    data: any,
    isSuperRealm: any,
    permissions: {
        canUpdateSystemWidget: any;
        canUpdateUserWidget: any;
        canDeleteUserWidget: any;
        canCreateUserWidget: any;
        canDeleteSystemWidget: any;
        canCreateSystemWidget: any;
    },
    getActionsBtn: any,
    navigate: any,
    currentRealmName: any
): JSX.Element =>
    !isSuperRealm ? (
        <Stack key={data?.id} direction={'row'} alignItems={'center'} gap={1}>
            {((data?.isSystemWidget && permissions.canUpdateSystemWidget) ||
                (!data?.isSystemWidget && permissions.canUpdateUserWidget) ||
                (!data?.isSystemWidget && permissions.canDeleteUserWidget) ||
                (!data?.isSystemWidget && permissions.canCreateUserWidget) ||
                (data?.isSystemWidget && permissions.canDeleteSystemWidget) ||
                (data?.isSystemWidget && permissions.canCreateSystemWidget)) && (
                <CustomMenu menuList={getActionsBtn(data)} />
            )}
            {((data?.isSystemWidget && permissions.canUpdateSystemWidget) ||
                (!data?.isSystemWidget && permissions.canUpdateUserWidget)) && (
                <IconButton id="chevron-right">
                    <ChevronRight
                        key={data?.id}
                        onClick={(): void => {
                            navigate(`/${currentRealmName}/editWidget/${data?.id}`, {
                                state: {
                                    typeId: data?.widgetType?.id,
                                },
                            });
                        }}
                        className="cursor-pointer"
                    />
                </IconButton>
            )}
        </Stack>
    ) : (
        <></>
    );

export const WidgetList = (): JSX.Element => {
    const currentRealmName = useTypedSelector((state) => state.common.selectedRealm);
    const navigate = useNavigate();
    const permissions = useTypedSelector(selectCurrentPermission);
    const { hasPermission } = useRBAC(permissions);
    const canDeleteSystemWidget = hasPermission('delete-system-widgets');
    const canDeleteUserWidget = hasPermission('delete-user-widgets');
    const canCreateSystemWidget = hasPermission('create-system-widgets');
    const canCreateUserWidget = hasPermission('create-user-widgets');
    const canUpdateUserWidget = hasPermission('edit-user-widgets');
    const canUpdateSystemWidget = hasPermission('edit-system-widgets');
    const currentOrg = useTypedSelector(selectedOrg);

    const cloneWidgetModalRef = React.useRef<any>(null);
    const renameWidgetRef = React.useRef<any>(null);
    const [date, setDate] = React.useState<any>(null);
    const { realmPrefix } = useParams();
    const [searchKey, setSearchKey] = React.useState<string | undefined>(undefined);

    const isMount = useIsMount();
    const [, debouncedValue] = useDebounce(undefined, undefined, searchKey);
    const { convertDateTime } = useTransformData();

    const deleteWidgetRef = React.useRef<any>(null);
    const tableRef: any = React.useRef(null);

    const { data: allWidgetTypes }: any = useGetAllWidgetTypesQuery(
        { headers: { 'x-realm': realmPrefix } },
        {
            skip: !currentOrg?.id && !realmPrefix,
        }
    );

    const [getDeviceModels, { data: allDeviceModels }]: any = useGetDeviceModelsByCategoryMutation();

    const isSuperRealm = useTypedSelector((state) => state.common.isSuperRealm);

    React.useEffect(() => {
        getDeviceModels({ category: 'All' });
    }, []);

    React.useEffect(() => {
        if (date) {
            setPayload((prev: any) => ({
                ...prev,
                page: 0,
                filters: { ...prev.filters, updatedAt: new Date(date)?.getTime() + 86399000 },
            }));
        }
    }, [date]);

    React.useEffect(() => {
        if (!isMount) {
            setPayload((prev: any) => ({
                ...prev,
                page: 0,
                searchKey: debouncedValue,
            }));
        }
    }, [debouncedValue]);

    const handleDelete = (widgetData: any): void => {
        if (widgetData?.usedCount) {
            deleteWidgetRef?.current?.handleModalAction(true, widgetData);
        } else {
            deleteWidgetRef?.current?.handleModalAction(false, widgetData);
        }
    };
    const handleRename = (widgetData: any): void => {
        if (widgetData?.usedCount) {
            renameWidgetRef?.current?.handleModalAction(true, widgetData);
        } else {
            renameWidgetRef?.current?.handleModalAction(false, widgetData);
        }
    };
    const handleClone = (widgetData: any): void => {
        cloneWidgetModalRef?.current?.handleModalAction(true, widgetData);
    };
    const [payload, setPayload] = React.useState<any>({
        page: 0,
        size: 10,
        filters: {},
    });

    const getName = (data: any): JSX.Element => (
        <Stack direction={'row'} alignItems={'center'} gap={1}>
            <Avatar sx={{ bgcolor: '#EEF0F0', color: '#727E84' }}>
                <CustomIcon
                    iconName={data?.widgetType?.['icon']?.web?.name}
                    family={data?.widgetType?.['icon']?.web?.family}
                />
            </Avatar>
            <Typography variant="body1" fontSize={'14px'} fontWeight={'600'}>
                {data['name'] ?? '--'}
            </Typography>
        </Stack>
    );

    const getActionsBtn = (data: any): any => {
        const menuGroups = [];

        if ((data?.isSystemWidget && canUpdateSystemWidget) || (!data?.isSystemWidget && canUpdateUserWidget)) {
            menuGroups?.push(
                {
                    title: <CustomTransComponent translationKey={'COMMON:EDIT'} />,
                    icon: <Edit />,
                    onClick: (): void => {
                        navigate(`/${currentRealmName}/editWidget/${data?.id}`, {
                            state: {
                                typeId: data?.widgetType?.id,
                            },
                        });
                    },
                },
                {
                    title: <CustomTransComponent translationKey={'COMMON:RENAME'} />,
                    icon: <DriveFileRenameOutline />,
                    onClick: (): any => handleRename(data),
                }
            );
        }

        if ((data?.isSystemWidget && canCreateSystemWidget) || (!data?.isSystemWidget && canCreateUserWidget)) {
            menuGroups.push({
                title: <CustomTransComponent translationKey={'COMMON:CLONE'} />,
                icon: <ContentCopy />,
                onClick: (): void => handleClone(data),
            });
        }
        if ((data?.isSystemWidget && canDeleteSystemWidget) || (!data?.isSystemWidget && canDeleteUserWidget)) {
            menuGroups.push({
                title: <CustomTransComponent translationKey={'COMMON:DELETE'} />,
                icon: <DeleteForever />,
                onClick: (): any => handleDelete(data),
            });
        }

        return {
            id: 'profile-menu',
            menuGroups,
            menuTitle: '',
            menuSubtitle: '',
        };
    };

    const getDescription = (data: any): JSX.Element => (
        <Typography variant="body1">{data?.description ?? '--'}</Typography>
    );
    const getDevice = (data: any): JSX.Element => (
        <Typography variant="body1">
            {getWidgetTypeValidationConfig(data?.widgetType?.id)?.isSourceRequired
                ? data?.sourceName ?? '(Linked)'
                : '-'}
        </Typography>
    );

    const handleWidgetType = (data: any): JSX.Element => (
        <Typography variant="body1">{data?.widgetType?.name ?? '--'}</Typography>
    );
    const getAssignment = (data: any, key: string): JSX.Element =>
        !data?.isSystemWidget ? (
            <>
                <Typography variant="body1">{data?.[key]?.name ?? '--'}</Typography>
                <Typography variant="body2">{data?.[key]?.email ?? '--'}</Typography>
            </>
        ) : (
            <Typography variant="body1">
                <CustomTransComponent size={{ width: '100%' }} translationKey={'AUDIT_LOGS:SYSTEM'} />
            </Typography>
        );

    const getDateSx = (): any => ({
        width: 'auto !important',
        maxHeight: '500px !important',
    });

    const getDateHeaderOptions = (): JSX.Element => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar disableFuture value={date ?? null} onChange={(data: any): void => setDate(data)} />
        </LocalizationProvider>
    );

    const getUpdateTime = (data: any): JSX.Element =>
        data['updatedAt'] ? (
            <>
                <Typography variant="body1" fontSize={'14px'} fontWeight={'400'}>
                    {convertDateTime({
                        timestamp: data['updatedAt'],
                    })}
                </Typography>
                <Typography variant="body2" fontSize={'12px'} fontWeight={'400'}>
                    {convertDateTime({
                        timestamp: data['updatedAt'],
                        customFormat: 'HH:mm:ss',
                    })}
                </Typography>
            </>
        ) : (
            <>--</>
        );

    const handleFilterChange = (filters: any, sortData?: any): void => {
        if (JSON.stringify(filters) !== JSON.stringify(payload.filters)) {
            const customKeys: any = {
                ...(payload.filters?.updatedAt && { updatedAt: payload.filters?.updatedAt }),
            };
            setPayload((prev: any) => ({
                ...prev,
                page: 0,
                filters: { ...filters, ...(isMount ? payload.filters : {}), ...customKeys },
            }));
        }
        if (sortData && Object.keys(sortData)?.length) {
            setPayload((prev: any) => ({
                ...prev,
                page: 0,
                sort: sortData,
            }));
        }
    };

    const headers: Header[] = [
        {
            header: (<CustomTransComponent size={{ width: '100%' }} translationKey={'COMMON:NAME'} />) as any,
            width: '15%',
            accessor: 'name',
            isFilterable: true,
            isSortable: true,
            isDebounce: true,
            cell: getName,
            skeleton: (
                <Stack direction={'row'} alignItems={'center'} gap={1}>
                    <Skeleton animation="wave" variant="circular">
                        <Avatar />
                    </Skeleton>
                    <Skeleton>
                        <Typography>
                            <CustomTransComponent translationKey={'WIDGET_MANAGEMENT:WIDGET_NAME'} />
                        </Typography>
                    </Skeleton>
                </Stack>
            ),
        },
        {
            header: (<CustomTransComponent size={{ width: '100%' }} translationKey={'COMMON:ASSIGNMENT'} />) as any,
            width: '15%',
            accessor: 'assignment',
            isFilterable: true,
            cell: (data: any): JSX.Element => getAssignment(data, 'assignment'),
            filterOptions: [
                { id: 'all', label: 'All' },
                { id: 'SYSTEM', label: 'System' },
                { id: 'USER', label: 'User' },
            ],
        },
        {
            header: (
                <CustomTransComponent size={{ width: '100%' }} translationKey={'COMMON:LAST_MODIFICATION'} />
            ) as any,
            width: '20%',
            accessor: 'updatedAt',
            isFilterable: true,
            headerOptions: getDateHeaderOptions,
            typeVariant: 'body2',
            cell: getUpdateTime,
            headerSx: getDateSx,
            skeleton: <DateAndYearSkelton />,
        },
        {
            header: (<CustomTransComponent size={{ width: '100%' }} translationKey={'COMMON:TYPE'} />) as any,
            width: '15%',
            isFilterable: true,
            cell: handleWidgetType,
            accessor: 'widgetType',
            filterOptions: [{ id: 'all', label: 'All' }].concat(
                allWidgetTypes?.data?.records?.map((widgetType: any) => ({
                    id: widgetType?.id,
                    label: widgetType?.label,
                }))
            ),
        },
        {
            header: (<CustomTransComponent size={{ width: '100%' }} translationKey={'COMMON:DEVICE'} />) as any,
            width: '15%',
            isFilterable: true,
            accessor: '   , v',
            filterOptions: [{ id: 'all', label: 'All' }].concat(
                allDeviceModels?.data?.map((model: any) => ({
                    id: model?.id,
                    label: model?.name,
                }))
            ),
            cell: getDevice,
        },
        {
            header: (<CustomTransComponent size={{ width: '100%' }} translationKey={'COMMON:USED_ON'} />) as any,
            width: '5%',
            accessor: 'usedCount',
        },
        {
            header: (<CustomTransComponent size={{ width: '100%' }} translationKey={'COMMON:DESCRIPTION'} />) as any,
            width: '15%',
            cell: getDescription,
        },
        {
            header: '  ',
            width: '10%',
            accessor: 'navigate',
            cell: (data: any): JSX.Element =>
                getNavigateCell(
                    data,
                    isSuperRealm,
                    {
                        canUpdateSystemWidget,
                        canUpdateUserWidget,
                        canDeleteUserWidget,
                        canCreateUserWidget,
                        canDeleteSystemWidget,
                        canCreateSystemWidget,
                    },
                    getActionsBtn,
                    navigate,
                    currentRealmName
                ),
            skeleton: (
                <Stack direction={'row'} alignItems={'center'} gap={1.5}>
                    <Skeleton animation="wave" variant="circular">
                        <Avatar />
                    </Skeleton>
                    <Skeleton animation="wave" variant="circular">
                        <Avatar />
                    </Skeleton>
                </Stack>
            ),
        },
    ];
    const handleWidgetPageChange = (page: any, size: any): void => {
        setPayload((prev: any) => ({ ...prev, page: page, size: size }));
    };

    const handleChipDelete = (key: any): void => {
        tableRef?.current?.resetFilters(key === 'groupId' ? 'groupPath' : key, true);
        const paginationPayloadClone = JSON.parse(JSON.stringify(payload));
        delete paginationPayloadClone?.filters[key];
        setPayload(paginationPayloadClone);
        if (key === 'updatedAt') {
            setDate(null);
        }
    };

    const getModifiedData = (filters: any): any => {
        const modifiedFilters: any = JSON.parse(JSON.stringify(filters));
        if (modifiedFilters?.assignment?.length > 1) {
            modifiedFilters['assignment'] = 'ALL';
        } else if (modifiedFilters?.assignment?.length) {
            modifiedFilters['assignment'] = modifiedFilters?.assignment[0];
        }
        return modifiedFilters;
    };

    const [tableFilters] = useFilteredValues({ allFilters: headers, triggeredFilters: payload?.filters });
    const {
        data: widgetsData,
        isLoading,
        isFetching,
    }: any = useGetAllWidgetsQuery(
        {
            body: { ...payload, filters: getModifiedData(tableFilters) },
            ...(realmPrefix && {
                headers: {
                    'x-realm': realmPrefix,
                },
            }),
        },
        { skip: !currentOrg?.id && !realmPrefix, refetchOnMountOrArgChange: true }
    );

    const getLabel = (key: string): string => {
        switch (key) {
            case 'assignType':
                return 'Type';
            case 'updatedAt':
                return 'Last Modification';
            case 'sourceId':
                return 'Device';
            default:
                return toFirstLetterUpperCase(key);
        }
    };

    return (
        <Stack p={3}>
            <Stack direction={'row'} spacing={2} justifyContent={'space-between'} alignItems={'center'}>
                <InputWithCustomPlaceholder
                    id="searchKey"
                    value={searchKey ?? ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                        setSearchKey(e.target.value);
                    }}
                    placeholder={'COMMON:SEARCH_PLACEHOLDER'}
                />

                {payload?.filters && (
                    <Stack direction="row" spacing={1}>
                        {Object.keys(payload?.filters).map((key: string) => (
                            <Chip
                                key={`unique${key}`}
                                label={getLabel(key)}
                                onDelete={(): void => {
                                    handleChipDelete(key);
                                }}
                            />
                        ))}
                    </Stack>
                )}
            </Stack>
            <Paper className="margin-top-24">
                <CustomTable
                    ref={tableRef}
                    isPagination
                    handlePageChange={handleWidgetPageChange}
                    isLoading={isLoading || isFetching}
                    data={widgetsData?.data?.records ?? []}
                    noDataFoundIcon={<DashboardCustomize fontSize="inherit" />}
                    noDataFoundTitle={<CustomTransComponent translationKey={'WIDGETS:NO_WIDGET'} />}
                    keyToTraverse="id"
                    total={widgetsData?.data?.total}
                    headers={headers}
                    handleFilterChange={handleFilterChange}
                    containerHeight={280}
                />
            </Paper>
            <DeleteWidgetModal ref={deleteWidgetRef} />
            <RenameWidgetModal ref={renameWidgetRef} />
            <CloneWidgetModal ref={cloneWidgetModalRef} />
        </Stack>
    );
};
