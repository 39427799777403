import React from 'react';
import { Divider, IconButton, Paper, Stack, Typography } from '@mui/material';
import List from '@mui/material/List';
import { ListItemTag } from '@brightlayer-ui/react-components';
import SimpleCardSkeleton from '../SimpleCardSkeleton';
import { useTheme } from '@mui/material/styles';
import { getListItemBackgroundColor, getListItemTextColor, getTimelineBorder } from 'utils/helpers';
import { ChevronRight } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { useTypedSelector } from '@fiji/common';
import { getAlarmLabelValue } from '@fiji/common/src/utils/helpers';
import { CustomTransComponent } from 'components';

export const EventDetails = ({
    isLoading,
    data,
    convertDateTime,
}: {
    isLoading: boolean;
    data: any;
    convertDateTime: any;
}): JSX.Element => {
    const theme: any = useTheme();
    const { timeLineId } = useParams();
    const navigate = useNavigate();
    const currentRealmName = useTypedSelector((state: any) => state.common.selectedRealm);

    // const getListItemText = (): string => {
    //     if (data?.data?. === 'Alarm') {
    //         return 'Offline';
    //     } else if (data?.data?.type === 'Alarm Cleared') {
    //         return 'Closed';
    //     } else if (data?.data?.type === 'Warning Cleared' || data?.data?.type === 'Offline Cleared') {
    //         return 'Cleared';
    //     }
    //     return data?.data?.type;
    // };

    return (
        <Paper>
            <List disablePadding>
                {isLoading ? (
                    <SimpleCardSkeleton count={6} />
                ) : (
                    <>
                        <Stack
                            direction={'row'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            className="padding-16"
                        >
                            <Typography
                                sx={{ fontSize: '14px', color: theme?.palette?.primary?.main }}
                                fontWeight={'600'}
                            >
                                <CustomTransComponent translationKey={'DASHBOARD_MANAGEMENT:EVENT_DETAILS'} />
                            </Typography>
                            <Stack direction={'row'} alignItems={'center'} spacing={2}>
                                {/* <Typography className="black-500" variant="body1" fontSize={'14px'}>
                                    45 minutes ago
                                </Typography> */}
                                <Typography className="text-secondary" fontWeight={'600'} fontSize={'14px'}>
                                    {data?.data?.alarmUpdated
                                        ? `${convertDateTime({
                                              timestamp: parseInt(data?.data?.alarmUpdated),
                                          })}, ${convertDateTime({
                                              timestamp: parseInt(data?.data?.alarmUpdated),
                                              customFormat: 'HH:mm:ss',
                                          })} `
                                        : '--'}
                                </Typography>
                            </Stack>
                        </Stack>
                        <Divider />
                        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} p={2}>
                            <Typography className="text-secondary" fontSize={'15px'}>
                                <CustomTransComponent translationKey={'COMMON:EVENT_NAME'} />
                            </Typography>
                            <Typography fontWeight={'600'} fontSize={'15px'} className="text-secondary">
                                {data?.data?.displayName ?? data?.data?.name ?? '-'}
                            </Typography>
                        </Stack>
                        {data?.data?.type !== 'Informational' && (
                            <>
                                <Divider />
                                <Stack p={2} direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                    <Typography className="text-secondary" fontSize={'15px'}>
                                        <CustomTransComponent translationKey={'COMMON:STATUS'} />
                                    </Typography>
                                    <ListItemTag
                                        sx={{
                                            border: getTimelineBorder(data?.data),
                                        }}
                                        label={getAlarmLabelValue(data?.data)}
                                        fontColor={getListItemTextColor(data?.data)}
                                        backgroundColor={getListItemBackgroundColor(data?.data, true)}
                                    />
                                </Stack>
                                <Divider />
                            </>
                        )}

                        {data?.data?.severity !== 'Offline' && (
                            <Stack p={2} direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                <Typography className="text-secondary" fontSize={'15px'}>
                                    <CustomTransComponent translationKey={'COMMON:SEVERITY'} />
                                </Typography>
                                <Typography fontWeight={'600'} fontSize={'15px'} className="text-secondary">
                                    {data?.data?.severity === 'Alarm' ? 'Critical' : data?.data?.severity ?? '-'}
                                </Typography>
                            </Stack>
                        )}

                        {data?.data?.deviceTimeline && Object.keys(data?.data?.values)?.length ? (
                            Object.keys(data?.data?.values)?.map((item: any) => (
                                <>
                                    <Divider />
                                    <Stack
                                        p={2}
                                        direction={'row'}
                                        alignItems={'center'}
                                        justifyContent={'space-between'}
                                    >
                                        <Typography className="text-secondary" fontSize={'15px'}>
                                            {item}
                                        </Typography>
                                        <Typography fontWeight={'600'} fontSize={'15px'} className="text-secondary">
                                            {data?.data?.values[item] ?? 0}
                                        </Typography>
                                    </Stack>
                                    <Divider />
                                </>
                            ))
                        ) : (
                            <></>
                        )}
                        <Divider />
                        <Stack
                            direction={'row'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            className="padding-16"
                        >
                            <Typography
                                sx={{ fontSize: '14px', color: theme?.palette?.primary?.main }}
                                fontWeight={'600'}
                            >
                                <CustomTransComponent translationKey={'TIMELINE:VIEW_ALL_EVENT_DETAILS'} />
                            </Typography>
                            <Stack direction={'row'} alignItems={'center'} spacing={2}>
                                <IconButton
                                    onClick={(): void => navigate(`/${currentRealmName}/event-details/${timeLineId}`)}
                                >
                                    <ChevronRight />
                                </IconButton>
                            </Stack>
                        </Stack>
                    </>
                )}
            </List>
        </Paper>
    );
};
