import React from 'react';

import { useConfirm } from '@fiji/common/src/hooks';
import { CustomModal } from 'components';
import { ModalActions } from 'components/CustomModal';
import { useHandleUpdate } from './hooks';

import { useAppDispatch } from '@fiji/common/src/app/store';
import { resetDeviceProperties, setUpdateProperties } from '@fiji/common/src/features/deviceManagement/deviceSlice';
import { DateUpdateModal, LocationUpdateModal, ModalHierarchy, NameOrDescriptionModal, UpdateTagsModal } from './';
import { useCreateTagMutation } from '@fiji/common/src/features/TagManagement/tagManagementApi';
import { t } from 'i18next';

export const UpdateModal = React.forwardRef((props: any, ref): JSX.Element => {
    const dispatch = useAppDispatch();
    const { data, isVisible, onClick, onCancel } = useConfirm();
    const [createTag, { isLoading: isTagCreating }] = useCreateTagMutation();
    const { handleDisable, handleModalHeader, handleModalType, isLoading, handleSubmit } = useHandleUpdate();

    const updateModalActions: ModalActions[] = [
        {
            key: 'cancel',
            label: t('COMMON:CANCEL'),
            close: true,
            variant: 'outlined',
            handleClick: (): void => {
                onCancel();
                dispatch(resetDeviceProperties());
            },
        },
        {
            key: 'save',
            label: isLoading ? t('COMMON:SAVING') : t('COMMON:SAVE_LABEL'),
            disabled: handleDisable(data) || isTagCreating || isLoading,
            isLoading: isTagCreating || isLoading,
            variant: 'contained',
            handleClick: (): void => {
                handleSubmit(data?.key, onCancel, data?.id);
            },
        },
    ];

    React.useImperativeHandle(
        ref,
        () => ({ handleModalAction: (args0: boolean, args1?: any) => handleModalAction(args0, args1) }),
        []
    );

    const handleModalAction = (action: boolean, args?: any): void => {
        if (action) {
            onClick(args);
            return;
        }
        onCancel();
    };

    const renderModalContent = (): JSX.Element => {
        switch (data?.key) {
            case 'groupId':
                return <ModalHierarchy />;
            case 'batteryInstallDate':
                return <DateUpdateModal type={data?.key} apiData={props?.allProperties} />;
            case 'deviceInstallDate':
                return <DateUpdateModal type={data?.key} apiData={props?.allProperties} />;
            case 'tags':
                return <UpdateTagsModal data={data} createHandler={createTag} />;
            case 'name':
            case 'description':
                return <NameOrDescriptionModal type={data?.key} />;
            case 'location':
                return <LocationUpdateModal />;
            default:
                return <></>;
        }
    };

    React.useEffect(() => {
        if (data?.value) {
            if (data?.key === 'groupId') {
                dispatch(
                    setUpdateProperties({
                        key: 'groupId',
                        value: { id: data?.value?.groupId, name: data?.value?.groupName },
                    })
                );
                return;
            }
            if (Array?.isArray(data?.value)) {
                dispatch(
                    setUpdateProperties({
                        key: data?.key,
                        value: data?.value?.map((item: any) => item.name),
                    })
                );
                return;
            }
            dispatch(
                setUpdateProperties({
                    key: data?.key,
                    value: data?.value,
                })
            );
        }
    }, [data, isVisible]);

    return (
        <CustomModal
            isOpen={isVisible}
            isLoading={isLoading}
            actions={updateModalActions}
            actionsDivider
            header={props?.header ?? handleModalHeader(data?.key)}
            {...(props?.isSubHeader && { subHeader: data?.name })}
            type={handleModalType(data?.key)}
        >
            {renderModalContent()}
        </CustomModal>
    );
});
