import React from 'react';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import { Card, CardContent, Divider } from '@mui/material';
import { Loader } from './Loader';
import * as MUIIcons from '@mui/icons-material';
import { RootState, useAppDispatch, useTypedSelector } from '@fiji/common';
import { setIsCustomWidgetLoaded } from '@fiji/common/src/features/widgetManagement/widgetConfigurationSlice';
import { useParams } from 'react-router-dom';
import { WidgetFooter, WidgetHeader } from 'pages/WidgetManagement/common';
import * as MaterialUI from '@mui/material';
import * as MUIStyles from '@mui/styles';
import { toCamelCaseAndCapitalize } from '@fiji/common/src/utils/helpers';
import { selectedOrg } from '@fiji/common/src/features/orgManagement/orgSlice';
import { EmptyState } from '@brightlayer-ui/react-components';
// import { DetailsCardSkeleton } from 'pages/WidgetManagement/common/Skeletons';

const handleAddScriptToHead = (widgetTypeId: string, cdnLink: string, callback: any): void => {
    const scriptTag = document.createElement('script');
    scriptTag.setAttribute('src', cdnLink);
    scriptTag.id = widgetTypeId;
    document.head.appendChild(scriptTag);
    scriptTag.onload = (): void => {
        callback();
    };
};

const handleLoadDynamicComponent = (widgetTypeId: string, cdnLink: string, callback: any): any => {
    const existScriptTag: any = document.querySelector(`script#${widgetTypeId}`);
    if (!existScriptTag) {
        handleAddScriptToHead(widgetTypeId, cdnLink, callback);
    } else if (existScriptTag?.src && existScriptTag.src !== cdnLink) {
        existScriptTag.remove();
        handleAddScriptToHead(widgetTypeId, cdnLink, callback);
    }
};

const CustomWidgetWrapper = ({ children, ...props }: any): JSX.Element => (
    <Card sx={{ height: '100%' }}>
        <WidgetHeader mode={props?.mode} widgetData={props?.widgetData} />
        {props?.mode !== 'edit' && <Divider />}
        <CardContent
            className="padding-0"
            sx={{
                height: `calc(100% - ${props?.mode === 'edit' || props?.widgetData?.viewAllButton ? '97px' : '49px'})`,
                position: 'relative',
            }}
        >
            {children}
        </CardContent>
        <WidgetFooter mode={props?.mode} widgetData={props?.widgetData} ids={[]} disabled={false} />
    </Card>
);

export const DynamicWidget = ({ widgetTypeId, ...props }: any): JSX.Element => {
    const dispatch = useAppDispatch();
    const { groupId, deviceId, ...rest } = useParams();
    const currentOrg = useTypedSelector(selectedOrg);
    const token = useTypedSelector((state) => state.auth?.token);
    const realmName = useTypedSelector((state: RootState) => state.common.selectedRealm);
    const isCustomWidgetLoaded = useTypedSelector(
        (state) => state.widgetConfiguration.isCustomWidgetLoaded[widgetTypeId]
    );
    window.React = React;
    window.MUIIcons = MUIIcons;
    window.MaterialUI = MaterialUI;
    window.MUIStyles = MUIStyles;
    React.useEffect(() => {
        if (props?.widgetData?.internalCdnJsLink) {
            handleLoadDynamicComponent(widgetTypeId, props?.widgetData?.internalCdnJsLink, () => {
                dispatch(setIsCustomWidgetLoaded({ [widgetTypeId]: true }));
            });
        }
    }, []);

    try {
        const DynamicComponent = require(`../pages/WidgetManagement/Widgets/${widgetTypeId}/Component`).default;
        return (
            // <Suspense
            //     fallback={
            //         <Card sx={{ height: '100%' }}>
            //             <CardContent className="padding-0 custom-card-height">{renderLoader(widgetTypeId)}</CardContent>
            //         </Card>
            //     }
            // >
            <DynamicComponent ref={props?.widgetRef} {...props} widgetTypeId={widgetTypeId} />
            // </Suspense>
        );
    } catch (error) {
        if (props?.widgetData?.internalCdnJsLink && !isCustomWidgetLoaded) {
            return (
                <CustomWidgetWrapper {...props}>
                    <Loader size={55} />
                </CustomWidgetWrapper>
            );
        } else if (props?.widgetData?.internalCdnJsLink && isCustomWidgetLoaded) {
            const CustomWidgetName: any = toCamelCaseAndCapitalize(widgetTypeId);
            const DynamicCustomWidget: any = window[CustomWidgetName];
            return (
                <CustomWidgetWrapper {...props}>
                    <DynamicCustomWidget
                        deviceId={deviceId}
                        groupId={groupId}
                        realmName={realmName}
                        orgId={currentOrg?.id}
                        token={token}
                        {...rest}
                    />
                </CustomWidgetWrapper>
            );
        }
        return (
            <EmptyState
                icon={<ErrorOutline className="height-100 width-100" />}
                title={'Widget Not Found'}
                className="padding-y-80"
            />
        );
    }
};
